import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import CentreAlignedButton from './centered-button'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { getStaff } from './auth//app-staff'
import TimeSheetDisplay from './timesheet-display'
import getNSWPubHolidays from '../helpers/public-holidays'
import DropdownInput from './dropdown-input'
import { isManager } from './auth//app-staff'
import { fetchStaffs, getTimeSheets } from '../helpers/timesheet-graphql'

const TimeSheetSummary = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [timeSheets, setTimeSheets] = useState([])
  const [holidays, setHolidays] = useState([])
  const [staff, setStaff] = useState(null)
  const [staffs, setStaffs] = useState([])

  //Get staff list
  useEffect(() => {
    const initStaffs = async() => {
      const staffList = await fetchStaffs()
      setStaffs(staffList)
      const staffInfo = getStaff()
      setStaff({
        staffID: staffInfo.username,
        name: `${staffInfo.given_name} ${staffInfo.family_name}`
      })
    }

    initStaffs()
  }, [])

  const fetchTimeSheets = async() => {
    const staffID = staff.staffID
    const dateStart = new Date(startDate)
    const dateEnd = new Date(endDate)
    dateStart.setHours(0)
    dateEnd.setHours(23)

    const ts = await getTimeSheets(staffID, dateStart, dateEnd)
    setTimeSheets(ts)
    const startYear = startDate.getFullYear()
    const startYearHolidays = await getNSWPubHolidays(startYear)
    const endYear = endDate.getFullYear()
    let endYearHolidays = []
    //The period crosses year boundary 
    if (endYear !== startYear) 
      endYearHolidays = await getNSWPubHolidays(endYear)
    
    setHolidays(startYearHolidays.concat(endYearHolidays))
  }

  const getLoading = (ts) => {
    const tsDate = new Date(ts.arrivalTime)
    /*
     * penaltyType - 0: regular, 1: Sat after 3:00pm, 2: Sun/Public holidays
     */
    let regularHours, penaltyHours, penaltyType, penaltyNote
    const endTick = new Date(ts.departureTime).getTime()
    const startTick = tsDate.getTime()
    const duration = (endTick - startTick) / 3600000

    if (tsDate.getDay() === 0) {
      regularHours = 0
      penaltyHours = duration
      penaltyType = 2
      penaltyNote = 'Sunday'
    }
    else {
      const dateWord = ts.departureTime.substring(0, 10).replace(/-/g, "")
      const aHoliday = holidays.filter(holiday => holiday.id === dateWord)

      if (aHoliday.length > 0) {
        regularHours = 0
        penaltyHours = duration
        penaltyType = 2
        penaltyNote = `${aHoliday[0].name}`
      } else if (tsDate.getDay() === 6) {
        tsDate.setHours(15, 0, 0, 0)
        const local3pmTick = tsDate.getTime()
        if (endTick <= local3pmTick) {
          regularHours = duration
          penaltyHours = 0
          penaltyType = 0
          penaltyNote = ''
        } else {
          regularHours = (local3pmTick - startTick) / 3600000
          penaltyHours = (endTick - local3pmTick) / 3600000 
          penaltyType = 1
          penaltyNote = 'Saturday after 3:00 PM'
        }
        
      } else {
        regularHours = duration
        penaltyHours = 0
        penaltyType = 0
        penaltyNote = ''
      }
    }

    return {
      regularHours,
      penaltyHours,
      penaltyType,
      penaltyNote
    }
  }


  const items = timeSheets.map((timeSheet) => {
    let item = {...timeSheet}
    const { regularHours, penaltyHours, penaltyType, penaltyNote } = getLoading(timeSheet)
    item.regularHours = regularHours
    item.penaltyHours = penaltyHours
    item.penaltyType = penaltyType
    item.penaltyNote = penaltyNote
    return item
  })  

  return (
    <>
      <Grid container justify="space-between" alignItems='center'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Select time sheet start date*"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item align="left" md={3}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="end-date-picker"
              label="Select time sheet end date*"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={3}>
          <DropdownInput option={staff} setOption={setStaff} options={staffs} label='Staff' placeholder='' required disabled={!isManager()}/>
        </Grid>
        <Grid item align="left" md={3}>
          <CentreAlignedButton buttonText='Summary' onClick={fetchTimeSheets} disabled={!(startDate && endDate)}/>          
        </Grid>
      </Grid>
      <TimeSheetDisplay items={items}/>
    </>
  )
}

export default TimeSheetSummary