import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CentreAlignedButton from './centered-button'
import useDebounce from '../helpers/debounce'
import DropdownInput from './dropdown-input'
import { listProductsByCode, createOrUpdateProduct } from '../helpers/product-graphql' 

const InventoryInput = ({product, updateCallback, suppliers, categories, stores}) => {
  const [productID, setProductID] = useState(null)
  const [productCode, setProductCode] = useState("")
  const [description, setDescription] = useState("")
  const [category, setCategory] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [productPackage, setProductPackage] = useState("")
  const [minStockQty, setMinStockQty] = useState(0)
  const [qty, setQty] = useState(0)
  const [store, setStore] = useState(null)
  const [shelves, setShelves] = useState("")
  const [existingProduct, setExistingProduct] = useState(false)
  const [updateRequestFromBrowser, setUpdateRequestFromBrowser] = useState(false)
  const debouncedProductCode = useDebounce(productCode, 1000)

  const reset = () => {
    setProductID(null)
    setProductCode("")
    setDescription("")
    setCategory(null)
    setSupplier(null)
    setProductPackage("")
    setMinStockQty(0)
    setQty(0)
    setStore(null)
    setShelves("")
  }

  const initProduct = (product) => {
    setProductID(product.id)
    setExistingProduct(true)
    setProductCode(product.productCode)
    setDescription(product.description)
    setCategory({name: product.category})
    setSupplier({name: product.supplier})
    setProductPackage(product.package)
    setMinStockQty(product.minStockQty)
    setQty(product.qty)
    setStore({name: product.store.name})
    console.log(product)
    const shelfList = product.audits.items.map(item => item.shelf.name)
    setShelves(shelfList.join(', '))
  }

  useEffect(() => {
    if (product) {
      initProduct(product)
      setUpdateRequestFromBrowser(true) 
    }
  }, [product])
  
  useEffect(() => {
    if (debouncedProductCode.length > 3) 
      listProductsByCode(productCode, initProduct, reset)
  // eslint-disable-next-line react-hooks/exhaustive-deps      
  }, [debouncedProductCode])

  const productIncomplete = description.length === 0 || category === null || supplier === null

  const handleSubmit = async () => {
    let productData = {
      productCode: productCode,
      description: description.trim(),
      category: category.name,
      supplier: supplier.name,
      package: productPackage.trim(),
      minStockQty: minStockQty,
      qty: qty,
      storeID: store.id,
      obsolete: false
    }

    createOrUpdateProduct(productID, productData, existingProduct, updateRequestFromBrowser, setUpdateRequestFromBrowser, updateCallback)
    setProductCode("")
    reset()
  }  

  return (
    <>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <Grid item align="left" md={3}>
          <TextField
            margin="dense"
            label="Product code"
            type="text"
            fullWidth
            value={productCode}
            onChange={(event) => setProductCode(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={category}
            setOption={setCategory}
            options={categories}
            label="Product category"
            placeholder="category"
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={supplier}
            setOption={setSupplier}
            options={suppliers}
            label="Product supplier"
            placeholder="supplier"
          />
        </Grid>
      </Grid>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="package"
            type="text"
            fullWidth
            value={productPackage}
            onChange={(event) => setProductPackage(event.target.value)}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Min stock qty"
            type="number"
            fullWidth
            value={minStockQty}
            onChange={(event) => setMinStockQty(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Qty"
            type="number"
            fullWidth
            value={qty}
            onChange={(event) => setQty(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={store}
            setOption={setStore}
            options={stores}
            label="Main Storage"
            placeholder="store"
          />
        </Grid>          
      </Grid>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <TextField
          margin="dense"
          label="Shelves"
          type="text"
          fullWidth
          value={shelves}
          disabled
        />
    </Grid>
      <CentreAlignedButton buttonText='Submit' onClick={handleSubmit} disabled={productIncomplete}/>
    </>
  )
}

export default InventoryInput