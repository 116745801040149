import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CentreAlignedButton from './centered-button'
import DropdownInput from './dropdown-input'
import { fetchAudits, createOrUpdateAudit, fetchAuditProducts } from '../helpers/audit-graphql'
import { addReport, getLatestAuditReport } from '../helpers/report-graphql'
import AuditProductEdit from './inventory-audit-edit'
import moment from 'moment'
import { getStaff } from './auth//app-staff'
import { genAuditPDF } from '../helpers/gen-pdf'

const InventoryAudit = () => {
  const docsPath = process.env.GATSBY_ASPIRE_ADMIN_DOCS_ROOT
  const [audit, setAudit] = useState(null)
  const [audits, setAudits] = useState([])
  const [products, setProducts] = useState([])
  const [auditName, setAuditName] = useState('')
  const [auditNote, setAuditNote] = useState('')
  const [auditFrequency, setAuditFrequency] = useState('')
  const [auditID, setAuditID] = useState(null)
  const [existingAudit, setExistingAudit] = useState()
  const [checked, setChecked] = useState([false])
  const [index, setIndex] = useState(null)  // The index of the product that is being edited
  const [triggerOpen, setTriggerOpen] = useState(false)
  const [disableReport, setDisableReport] = useState(true)
  const [auditComment, setAuditComment] = useState('')
  const [auditUpdatedTrigger, setAuditUpdatedTrigger] = useState(false)
  const [auditReport, setAuditReport] = useState(null)
  const [reportCreatedAt, setReportCreatedAt] = useState(null)
  const [reportCreatedBy, setReportCreatedBy] = useState(null)

  useEffect(() => {
    const initAudits = async() => {
      const existingAudits = await fetchAudits()
      setAudits([{name: "Create a new audit"}, ...existingAudits])
    }

    initAudits()
  }, [auditUpdatedTrigger])

  const resetAudit = () => {
    setExistingAudit(false)
    setAuditID(null)
    setAuditName('')
    setAuditNote('')
    setAuditFrequency('')
    setProducts([])
  }

  useEffect(() => {
    const initAudit = async() => {
      if (audit === null)
        return

      if (audit.name === "Create a new audit") {
        resetAudit()
      } else {
        setExistingAudit(true)
        setAuditID(audit.id)
        setAuditName(audit.name)
        setAuditNote(audit.note ? audit.note : '')
        setAuditFrequency(audit.auditFrequency)
        const products = await fetchAuditProducts(audit.id)
        console.log(products)
        setProducts(products)
        setChecked(new Array(products.length).fill(false))
        getLatestAuditReport(audit.name, setAuditReport, setReportCreatedAt, setReportCreatedBy)
        setAuditComment('')
      }
    }
    initAudit()
  }, [audit])

  useEffect(() => {
    if (products.length > 0 && checked.length > 0)
      setDisableReport(!checked.every((currentValue => currentValue === true)))
    else
      setDisableReport(true)
  }, [checked, products])

  const saveAudit = async() => {
    const auditData = {
      name: auditName,
      note: auditNote,
      auditFrequency: auditFrequency
    }
    await createOrUpdateAudit(auditID, auditData, existingAudit)
    
    //Audit info changed => fetch the audit list again to get updated data
    setAuditUpdatedTrigger(!auditUpdatedTrigger)
    
    if (!existingAudit)
    {
      setAudit(null)
      resetAudit()
    }
  }

  const items = products.map((p, index) => (
    {
      id: index,
      product: p,
      handleChecked: (event) => {
        let values = [...checked]
        values[index] = !checked[index] 
        setChecked(values)
      }
    }
  ))

  const handleEdit = (e) => {
    //remember the index of the invoices that might be edited
    setIndex(e.currentTarget.value)
    setTriggerOpen(!triggerOpen)
  }

  const genReport = async() => {
    const staffInfo = getStaff()
    const staffName = `${staffInfo.given_name} ${staffInfo.family_name}`    
    const auditReport = await genAuditPDF(staffName, auditName, auditComment, products)
    setAuditReport(auditReport)
    setReportCreatedAt(moment(new Date()).format("YYYY-MM-DD hh:mm a"))
    setReportCreatedBy(staffName)
    const amount = '0'
    addReport(auditName, amount, staffName, auditReport)
  }

  return (
    <>
      <Grid container justify="space-between" alignItems='center' spacing={2}>
        <Grid item align="left" md={2}>
          <DropdownInput
            required
            disableClearable={true}
            option={audit}
            setOption={setAudit}
            options={audits}
            label="Select an audit"
            placeholder="audit"   
          />
        </Grid>
        <Grid item align="left" md={2}>
          <TextField
            required
            margin="dense"
            label="Audit name"
            type="text"
            fullWidth
            value={auditName}
            onChange={(event) => setAuditName(event.target.value)}
          />          
        </Grid>  
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Audit note"
            type="text"
            fullWidth
            value={auditNote}
            onChange={(event) => setAuditNote(event.target.value)}
          />          
        </Grid>  
        <Grid item align="left" md={2}>
          <TextField
            required
            margin="dense"
            label="Audit frequency"
            type="text"
            fullWidth
            value={auditFrequency}
            onChange={(event) => setAuditFrequency(event.target.value)}
          />          
        </Grid>  
        <Grid item align="left" md={1}>
          <CentreAlignedButton buttonText='Save' onClick={saveAudit} disabled={auditName.length === 0 || auditFrequency.length === 0}/>
        </Grid>  
        <Grid item align="left" md={1}>
          <CentreAlignedButton 
            buttonText='Report' 
            onClick={genReport} 
            disabled ={disableReport}
          />
        </Grid>  
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={2}>
        <Grid item align="left" md={4}>
          <Button color='primary' 
            href={auditReport ? `${docsPath}${auditReport}` : null}
            target="_blank" 
            disabled={auditReport === null}
          >
            Previous report
          </Button>              
        </Grid>
        <Grid item align="left" md={2}>
          created at
        </Grid>
        <Grid item align="left" md={3}>
          {reportCreatedAt}
        </Grid>
        <Grid item align="left" md={1}>
          by
        </Grid>
        <Grid item align="left" md={2}>
          {reportCreatedBy}
        </Grid>
      </Grid>
      <Grid container justify="space-between" style={{marginTop: 40}}>
        <Grid item align="left" md={5}>
          Product name
        </Grid>
        <Grid item align="left" md={2}>
          Expiry
        </Grid>
        <Grid item align="left" md={1}>
          Target qty
        </Grid>
        <Grid item align="left" md={3}>
          Shelf
        </Grid>
        <Grid item align="left" md={1}>
          Checked
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {items.map((item, index) => (
        <Grid container justify="space-between" key={item.id} alignItems="center" style={{marginBottom: 4}}>
          <Grid item align="left" md={5}>
            <Button color='primary'
              value={index}
              size="small"
              onClick={handleEdit}
            >
              {item.product.product.description}
            </Button>              
          </Grid>
          <Grid item align="left" md={2}>
            {item.product.expiry ? moment(item.product.expiry).format("YYYY-MM-DD") : null}
          </Grid>
          <Grid item align="left" md={1}>
            {item.product.qty ? item.product.qty : null}
          </Grid>
          <Grid item align="left" md={3}>
            {item.product.shelf ? item.product.shelf.name : null}
          </Grid>
          <Grid item style={{marginLeft: 0, marginRight: 0}} md={1}>
            <Checkbox
              checked={checked[index]}
              onChange={item.handleChecked}
              color='primary'
            />
          </Grid>
        </Grid>
      ))}
      {
        !disableReport
        &&
        <TextField
          margin="dense"
          label="Audit comment"
          type="text"
          fullWidth
          value={auditComment}
          onChange={(event) => setAuditComment(event.target.value)}
        />
      }
      <AuditProductEdit
        triggerOpen={triggerOpen}
        products={products}
        setProducts={setProducts}
        index={index}
      />
    </>
  )
}

export default InventoryAudit