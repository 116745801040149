import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CentreAlignedButton from './centered-button'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import getUTC from '../helpers/getutc'
import DropdownInput from './dropdown-input'
import paymentTypes from '../constants/payment-types.json'
import paymentStatuses from '../constants/payment-statuses.json'
import { getInvoiceByNumber, filterInvoices } from '../helpers/invoice-graphql'

const PaymentFilter = ({setInvoices}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [paymentType, setPaymentType] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [disbursed, setDisbursed] = useState(false)
  const [invoiceNo, setInvoiceNo] = useState("")

  const fetchInvoices = async() => {
    if (invoiceNo.length > 0) {
      const invoice = await getInvoiceByNumber(invoiceNo)
      setInvoices([invoice])
    }  
    else {
      let filter = {}
      if (paymentType)
        filter.paymentType = {eq: paymentType.name}
      if (paymentStatus)
        filter.status = {eq: paymentStatus.name}
      filter.claimDisbursedToProvider = {eq: disbursed}

      if (startDate && endDate) {
        const dateStart = new Date(startDate)
        const dateEnd = new Date(endDate)
        dateStart.setHours(0)
        dateEnd.setHours(23)
        filter.createdAt = {between: [getUTC(dateStart), getUTC(dateEnd)]}
      } else if (startDate) {
        const dateStart = new Date(startDate)
        dateStart.setHours(0)
        filter.createdAt = {gt: getUTC(dateStart)}
      } else if (endDate) {
        const dateEnd = new Date(endDate)
        dateEnd.setHours(23)
        filter.createdAt = {lt: getUTC(dateEnd)}
      }

      const invoices = await filterInvoices(filter)
      setInvoices(invoices)
    }
  }

  return (
    <>
      <Grid container justify="space-between" alignItems='center' spacing={10}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Select start date to search"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>  
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="end-date-picker"
              label="Select end date to search"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>  
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={4}>
          <DropdownInput
            option={paymentType}
            setOption={setPaymentType}
            options={paymentTypes}
            label="Payment type"
            placeholder=""
          />                    
        </Grid>  
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={10}>
        <Grid item align="left" md={3}>
          <TextField
            margin="dense"
            label="Invoice No."
            type="text"
            fullWidth
            value={invoiceNo}
            onChange={(event) => setInvoiceNo(event.target.value.trim())}
          />          
        </Grid>       
        <Grid item align="left" md={3}>
          <DropdownInput
            option={paymentStatus}
            setOption={setPaymentStatus}
            options={paymentStatuses}
            label="Payment status"
            placeholder=""
          />                                
        </Grid>       
        <Grid item align="right" md={4}>
          <FormGroup>
            <FormControlLabel 
              control={<Switch checked={disbursed} onChange={() => setDisbursed(!disbursed)} value='disbursed'/>} 
              label="Claim disbursed to provider" 
            />
          </FormGroup>             
        </Grid>            
        <Grid item align="left" md={2}>
          <CentreAlignedButton buttonText='Search' onClick={fetchInvoices}/>
        </Grid>            
      </Grid>
    </>
  )
}

export default PaymentFilter