import { Storage } from 'aws-amplify'

const uploadDoc = async(id, fileName) => {
  const reader = new FileReader()
  let arrayBuffer
  
  reader.onload = function(e) {
    arrayBuffer = reader.result
  }

  reader.onloadend = function(e) {
    Storage.put
    (
      fileName, 
      arrayBuffer, {
        contentType : 'application/pdf'
      }
    )
  }

  const file = document.getElementById(id).files[0];
  await reader.readAsArrayBuffer(file)
}

export default uploadDoc