import { API, graphqlOperation } from 'aws-amplify'
import { listPurchaseOrders, listItems }  from '../graphql/queries'
import { createPurchaseOrder, updatePurchaseOrder, createItem, updateItem, deleteItem, updateProduct }  from '../graphql/mutations'

export const fetchPurchaseOrders = async(filter) => {
  try {
    const result = await API.graphql({
      query: listPurchaseOrders,
      variables: {
        filter: filter,
        limit: 65536
      }        
    })
    const existingPOs = result.data.listPurchaseOrders.items
    return existingPOs
  } catch (err) {
    console.log('Amplify listPurchaseOrders error...: ', err)
  }
}

export const createPO = async(poData) => {
  try {
    const result = await API.graphql(graphqlOperation(createPurchaseOrder, {
      input: poData
    }))

    alert('Purchase order created successfully')

    return result.data.createPurchaseOrder.id

  } catch (err) {
    console.log('Amplify createPurchaseOrder error...: ', err)
    alert('Purchase order failed to create')
  }
}

export const updatePO = async(poData) => {
  try {
    await API.graphql(graphqlOperation(updatePurchaseOrder, {
      input: poData
    }))

    alert('Purchase order saved successfully')
  } catch (err) {
    console.log('Amplify updatePurchaseOrder error...: ', err)
    alert('Purchase order failed to update')
  }
}

export const updatePODoc = (poID, poDoc) => {
  try {
    API.graphql(graphqlOperation(updatePurchaseOrder, {
      input: {
        id: poID,
        orderDoc: poDoc
      }
    }))
  } catch (err) {
    console.log('Amplify updatePurchaseOrder error...: ', err)
    alert('Purchase order failed to save')
  }
}

export const updateInvoiceReceiptDoc = (poID, docType, fileName) => {
  let input = {
    id: poID
  }

  if (docType === 'invoice')
    input.invoiceDoc = fileName
  else
    input.receiptDoc = fileName

  try {
    API.graphql(graphqlOperation(updatePurchaseOrder, {
      input: input
    }))
  } catch (err) {
    console.log('Amplify updatePurchaseOrder error...: ', err)
    alert('Purchase order invoice/receipt failed to save')
  }
}

const existingItem = async(productID, poID) => {
  try {
    const result = await API.graphql({
      query: listItems,
      variables: {
        filter: {
          productID: {eq: productID},
          purchaseOrderID: {eq: poID}
        },
        limit: 65536
      }        
    })
    
    return result.data.listItems.items.length > 0

  } catch (err) {
    console.log('Amplify listItems error...: ', err)
  }  
}

export const addProductsToPurchaseOrder = async(productIDs, poID) => {
  if (productIDs.length === 0)
    return
    
  try {
    Promise.allSettled(productIDs.map(async(productID) => {
      const existing = await existingItem(productID, poID)
      if (!existing) {
        return API.graphql(graphqlOperation(createItem, {
          input: {
            productID,
            purchaseOrderID: poID,
            qty: 0,
            price: 0
          }}))
      }
    }))
    .then(() => {
      alert('Products added to purchase order successfully')
    })
  } catch (err) {
    console.log('Amplify createItem error...: ', err)
    alert('Products failed to add to purchase order')
  }
}

export const duplicateItemsForNewPO = async(items, poID) => {
  try {
    Promise.allSettled(items.map(async(item) => {
      return API.graphql(graphqlOperation(createItem, {
        input: {
          productID: item.productID,
          purchaseOrderID: poID,
          qty: item.qty,
          price:item.price
        }}))
    }))
  } catch (err) {
    console.log('Amplify createItem error...: ', err)
    alert('Items failed to duplicated')
  }
}

export const savePOItems = async(items) => {
  try {
    await Promise.allSettled(items.map(item => {
      const data = {
        id: item.id, 
        qty: item.qty, 
        price: item.price
      }    
      return API.graphql(graphqlOperation(updateItem, {input: data}))
    }))
  } catch (err) {
    console.log('Amplify updateItem error...: ', err)
    alert('Purchase order item failed to update')
  }
}

export const deletePOItem = async(id) => {
  try {
    await API.graphql(graphqlOperation(deleteItem, {
      input: {
        id: id
      }}))
    alert('Purchase order product deleted successfully')      
  } catch (err) {
    console.log('Amplify deleteItem error...: ', err)
    alert('Purchase order product failed to delete')
  }
}

export const fetchPOItems = async(poID) => {
  try {
    const result = await API.graphql({
      query: listItems,
      variables: {
        filter: {
          purchaseOrderID: {eq: poID}
        },
        limit: 65536
      }        
    })
    
    return result.data.listItems.items

  } catch (err) {
    console.log('Amplify listItems error...: ', err)
  }  
}

export const markPOItemsDelivered = async(poID, items) => {
  try {
    API.graphql(graphqlOperation(updatePurchaseOrder, {
      input: {
        id: poID,
        delivered: true
      }
    }))

    await Promise.allSettled(items.map(item => {
      return API.graphql(graphqlOperation(updateProduct, {
        input: {
          id: item.productID,
          qty: item.qty + item.product.qty,
        }}))
    }))

    alert("Purchase order item quantities added to store successfully")
  } catch (err) {
    console.log('Amplify updatePurchaseOrder error...: ', err)
    alert('Purchase order items failed to updated')
  }  
}
