import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default function DropdownInput({option, setOption, disableClearable=false, disabled=false, options, label, required, placeholder}) {
  return (
    <Autocomplete
      disabled={disabled}
      disableClearable={disableClearable}
      filterSelectedOptions
      options={options}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.name === value.name}
      value={option}
      onChange={(event, value) => setOption(value)}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          label={required ? `${label}*` : label}
          fullWidth
        />
      )}
    />
  )
}