import { API, graphqlOperation } from 'aws-amplify'
import { listAudits, listProductAudits }  from '../graphql/queries'
import { createAudit, updateAudit, createProductAudit, updateProductAudit, deleteProductAudit, updateProduct }  from '../graphql/mutations'

export const fetchAudits = async() => {
  try {
    const result = await API.graphql({
      query: listAudits,
      variables: {
        limit: 65536
      }        
    })
    const existingAudits = result.data.listAudits.items
    return existingAudits
  } catch (err) {
    console.log('Amplify listAudits error...: ', err)
  }
}

export const createOrUpdateAudit = async(auditID, auditData, existingAudit) => {
  try {
    if (existingAudit) {
      auditData.id = auditID
      await API.graphql(graphqlOperation(updateAudit, {
        input: auditData
      }))
    } else {
      await API.graphql(graphqlOperation(createAudit, {
        input: auditData
      }))
    }

    alert('Audit saved successfully')
  } catch (err) {
    console.log('Amplify createAudit/updateAudit error...: ', err)
    alert('Audit failed to save')
  }
}

const existingProductAudit = async(productID, auditID) => {
  try {
    const result = await API.graphql({
      query: listProductAudits,
      variables: {
        filter: {
          productID: {eq: productID},
          auditID: {eq: auditID}
        },
        limit: 65536
      }        
    })
    
    return result.data.listProductAudits.items.length > 0

  } catch (err) {
    console.log('Amplify listProductAudits error...: ', err)
  }  
}

export const addProductsToAudit = async(productIDs, auditID) => {
  if (productIDs.length === 0)
    return
    
  try {
    Promise.allSettled(productIDs.map(async(productID) => {
      const existing = await existingProductAudit(productID, auditID)
      if (!existing) {
        return API.graphql(graphqlOperation(createProductAudit, {
          input: {
            productID,
            auditID
          }}))
      }
    }))
    .then(() => {
      alert('Products added to audit successfully')
    })
  } catch (err) {
    console.log('Amplify createProductAudit error...: ', err)
    alert('Products failed to add to audit')
  }
}

export const updateProductInAudit = async(id, expiry, qty, shelfID, productID) => {
  let data = {id, qty, shelfID}
  if (expiry)
    data['expiry'] = expiry
  try {
    await API.graphql(graphqlOperation(updateProductAudit, {input: data}))
    alert('Audit product updated successfully')      
  } catch (err) {
    console.log('Amplify updateProductAudit error...: ', err)
    alert('Audit product failed to update')
  }
}

export const deleteProductInAudit = async(id) => {
  try {
    await API.graphql(graphqlOperation(deleteProductAudit, {
      input: {
        id: id
      }}))
    alert('Audit product deleted successfully')      
  } catch (err) {
    console.log('Amplify deleteProductAudit error...: ', err)
    alert('Audit product failed to delete')
  }
}

export const fetchAuditProducts = async(auditID) => {
  try {
    const result = await API.graphql({
      query: listProductAudits,
      variables: {
        filter: {
          auditID: {eq: auditID}
        },
        limit: 65536
      }        
    })
    
    return result.data.listProductAudits.items

  } catch (err) {
    console.log('Amplify listProductAudits error...: ', err)
  }  
}

export const getProductFromStore = (productID, updatedQty) => {
  try {
    API.graphql(graphqlOperation(updateProduct, {
      input: {
        id: productID,
        qty: updatedQty
      }
    }))
  } catch (err) {
    console.log('error updateProduct: ', err)
  }
}