import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import InventoryFilter from './inventory-filter'
import { fetchProduct } from '../helpers/product-graphql'

const InventoryBrowser = ({setProduct, setMode, products, setProducts, setIndex, suppliers, categories, stores}) => {
  const numProducts = products.length
  const [checked, setChecked] = useState(new Array(numProducts).fill(false))
  const [productIDs, setProductIDs] = useState([])

  useEffect(() => {
    if (products.length > 0) {
      const numProducts = products.length
      setChecked(new Array(numProducts).fill(false))
      setProductIDs([])
    }
  }, [products])

  const handleEdit = (e) => {
    //remember the index of the invoices that might be edited
    setIndex(e.currentTarget.value)
    fetchProduct(products[e.currentTarget.value].id, setProduct)
    setMode('input')    
  }

  const removeCallback = () => {
    let existingProducts = [...products]
    setProducts(existingProducts.filter(p => !productIDs.includes(p.id)))
    setProductIDs([])
  }

  const items = products.map((p, index) => (
    {
      id: index,
      product: p,
      handleChecked: (event) => {
        let values = [...checked]
        values[index] = !checked[index] 
        setChecked(values)
        if (!checked[index]) {
          const ids = [...productIDs, products[index].id]
          setProductIDs(ids)
        } else {
          setProductIDs(productIDs.filter(id => id !== products[index].id))
        }
      }
    }
  ))

  return (
    <>
      <InventoryFilter 
        setProducts={setProducts}
        suppliers={suppliers}
        categories={categories}
        stores={stores}
        removeCallback={removeCallback}
        productIDs={productIDs}
      />
      <Grid container justify="space-between" style={{marginTop: 40}}>
        <Grid item align="left" md={1}>
          Select
        </Grid>
        <Grid item align="left" md={2}>
          Product code
        </Grid>
        <Grid item align="left" md={5}>
          Description
        </Grid>
        <Grid item align="left" md={1}>
          Min stock qty
        </Grid>
        <Grid item align="left" md={1}>
          Qty
        </Grid>
        <Grid item align="left" md={2}>
          Supplier
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {items.map((item, index) => (
        <Grid container justify="space-between" key={item.id} alignItems="center" style={{marginBottom: 4}}>
          <Grid item style={{marginLeft: 0, marginRight: 0}} md={1}>
            <Checkbox
              checked={checked[index]}
              onChange={item.handleChecked}
              color='primary'
            />
          </Grid>
          <Grid item align="left" md={2}>
            {item.product.productCode}
          </Grid>
          <Grid item align="left" md={5}>
            <Button color='primary'
              value={index}
              size="small"
              onClick={handleEdit}
            >
              {item.product.description}
            </Button>              
          </Grid>
          <Grid item align="left" md={1}>
            {item.product.minStockQty}
          </Grid>
          <Grid item align="left" md={1}>
            {item.product.qty}
          </Grid>
          <Grid item align="left" md={2}>
            {item.product.supplier}
          </Grid>
        </Grid>
      ))}      
    </>
  )
}

export default InventoryBrowser