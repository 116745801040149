import React from 'react'
import PaymentFilter from './payment-filter'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import moment from 'moment'

const PaymentBrowser = ({setInvoice, setMode, invoices, setInvoices, setIndex}) => {
  const docsPath = process.env.GATSBY_ASPIRE_ADMIN_DOCS_ROOT

  const handleEdit = (e) => {
    //remember the index of the invoices that might be edited
    setIndex(e.currentTarget.value)
    setInvoice(invoices[e.currentTarget.value])
    setMode('input')
  }

  return (
    <>
      <PaymentFilter setInvoices={setInvoices}/>
      <Grid container justify="space-between" style={{marginTop: 40}}>
        <Grid item align="left" md={1}>
          Invoice No.
        </Grid>
        <Grid item align="left" md={1}>
          Invoice date
        </Grid>
        <Grid item align="left" md={2}>
          Patient
        </Grid>
        <Grid item align="left" md={2}>
          Provider
        </Grid>
        <Grid item align="left" md={1}>
          Amount
        </Grid>
        <Grid item align="left" md={1}>
          Insurer
        </Grid>
        <Grid item align="left" md={2}>
          Claim No.
        </Grid>
        <Grid item align="left" md={1}>
          Invoice
        </Grid>
        <Grid item align="left" md={1}>
          Cheque
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {invoices.map((item, index) => (
        <Grid container justify="space-between" key={item.id} alignItems="center" style={{marginBottom: 4}}>
          <Grid item align="left" md={1}>
            <Button color='primary'
              value={index}
              size="small"
              onClick={handleEdit}
            >
              {item.id}
            </Button>              
          </Grid>
          <Grid item align="left" md={1}>
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </Grid>
          <Grid item align="left" md={2}>
            {item.patient}
          </Grid>
          <Grid item align="left" md={2}>
            {item.provider}
          </Grid>
          <Grid item align="left" md={1}>
            {item.amount}
          </Grid>
          <Grid item align="left" md={1}>
            {item.insurer}
          </Grid>
          <Grid item align="left" md={2}>
            {item.claimNo}
          </Grid>
          <Grid item align="left" md={1}>
            <Link color='primary' href={`${docsPath}${item.invoiceDoc}`} target="_blank" underline="always">invoice</Link>
          </Grid>
          <Grid item align="left" md={1}>
            {item.chequeDoc
            ?
            <Link color='primary' href={`${docsPath}${item.chequeDoc}`} target="_blank" underline="always">cheque</Link>
            :
            null}
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default PaymentBrowser