import React from "react"
import { Router } from "@reach/router"
import TimeSheet from "../../components/timesheet"
import Payment from "../../components/payment"
import DocumentFinder from "../../components/document-finder"
import Inventory from "../../components/inventory"
import PrivateRoute from "../../components/private-route"
import { isAdmin } from '../../components/auth/app-staff'

const App = (theme) => {
  return (
    <Router basepath="/app">
      <PrivateRoute path="/time-sheet" allowed={isAdmin()} component={TimeSheet} />
      <PrivateRoute path="/payment" allowed={isAdmin()} component={Payment} />
      <PrivateRoute path="/document-finder" allowed={isAdmin()} component={DocumentFinder} />
      <PrivateRoute path="/inventory" allowed={isAdmin()} component={Inventory} />
    </Router>
  )
}
export default App