import { API, graphqlOperation } from 'aws-amplify'
import { listReports }  from '../graphql/queries'
import { createReport, updateReport }  from '../graphql/mutations'
import moment from 'moment'

const existingReport = async(tag, reportFile) => {
  try {
    const result = await API.graphql({
      query: listReports,
      variables: {
        filter: {
          tag: {eq: tag},
          reportFile: {eq: reportFile}
        },
        limit: 65536
      }        
    })
    //createdAt cannot be changed by update. As createdAt is a required field, we have to get the original to avoid missing fields.
    if (result.data.listReports.items.length > 0) {
      return {
        id: result.data.listReports.items[0].id,
        createdAt: result.data.listReports.items[0].createdAt
      }
    } else {
      return {
        id: null,
        createdAT: null
      }
    }
  } catch (err) {
    console.log('Amplify listReports error...: ', err)
  }  
}

export const addReport = async(tag, amount, createdBy, reportFile) => {
  const { id, createdAt } = await existingReport(tag, reportFile)
  try {
    if (id === null) {
      await API.graphql(graphqlOperation(createReport, {
        input: {
          tag,
          amount,
          createdBy,
          reportFile
        }
      }))
    } else {
      await API.graphql(graphqlOperation(updateReport, {
        input: {
          id,
          tag,
          amount,
          createdAt: createdAt,
          createdBy,
          reportFile
        }
      }))
    }
  } catch (err) {
    console.log('Amplify createReport error...: ', err)
    alert('Failed to add a report entry')
  }
}

export const filterReports = async(filter) => {
  try {
    const result = await API.graphql({
      query: listReports,
      variables: {
        filter: filter,
        limit: 65536
      }        
    })
    
    return result.data.listReports.items

  } catch (err) {
    console.log('Amplify listReports error...: ', err)
  }  
}

export const getLatestAuditReport = async(auditName, setAuditReport, setReportCreatedAt, setReportCreatedBy) => {
  try {
    const result = await API.graphql({
      query: listReports,
      variables: {
        tag: auditName,
        sortDirection: 'DESC',
        limit: 65536
      }        
    })
    
    if (result.data.listReports.items.length > 0) {
      const latest = result.data.listReports.items[0]
      setAuditReport(latest.reportFile)
      setReportCreatedAt(moment(latest.createdAt).format("YYYY-MM-DD hh:mm a"))
      setReportCreatedBy(latest.createdBy)
    } else {
      setAuditReport(null)
      setReportCreatedAt(null)
      setReportCreatedBy(null)
    }
  } catch (err) {
    console.log('Amplify listReports error...: ', err)
  }  
}
