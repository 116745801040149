import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import DropdownInput from './dropdown-input'
import orderStatuses from '../constants/po-status.json'
import { fetchPurchaseOrders, createPO, updatePO, duplicateItemsForNewPO, fetchPOItems, updatePODoc, 
  updateInvoiceReceiptDoc, markPOItemsDelivered, savePOItems } from '../helpers/po-graphql'
import { addReport } from '../helpers/report-graphql'  
import { getStaff } from './auth//app-staff'
import { genPOPDF } from '../helpers/gen-pdf'
import PopupAttachment from './popup-attachment'
import moment from 'moment'
import uploadDoc from '../helpers/uploadS3'

const PurchaseOrderFilter = ({suppliers, amount, setItems, items, purchaseOrder, setPurchaseOrder}) => {
  const docsPath = process.env.GATSBY_ASPIRE_ADMIN_DOCS_ROOT
  const staffInfo = getStaff()
  const staffName = `${staffInfo.given_name} ${staffInfo.family_name}`    
  const [pos, setPos] = useState([])
  const [poID, setPoID] = useState(null)
  const [poName, setPOName] = useState('')
  const [existingPO, setExistingPO] = useState()
  const [supplier, setSupplier] = useState(null)
  const [orderStatus, setOrderStatus] = useState('')
  const [delivered, setDelivered] = useState(false)
  const [poUpdatedTrigger, setPoUpdatedTrigger] = useState(false)
  const [createdAt, setCreatedAt] = useState(null)
  const [createdBy, setCreatedBy] = useState('')
  const [action, setAction] = useState(null)
  const [actions, setActions] = useState([])
  const [attachmentTrigger, setAttachmentTrigger] = useState(false)
  const [docType, setDocType] = useState('invoice')

  useEffect(() => {
    let actions = []

    if (existingPO) {
      if (purchaseOrder.orderStatus !== 'draft') {
        actions.push({
          name: "Duplicate a new purchase order from the selected one"
        })
        actions.push({
          name: "Update purchase order status"
        })
        actions.push({
          name: "Attach invoice document"
        })
        actions.push({
          name: "Attach receipt document"
        })
        if (!purchaseOrder.delivered)
          actions.push({
            name: "Mark delivered and update store quantity"
          })
      } else {
        actions.push({
          name: "Save purchase order"
        })

        if (items.length > 0) {
          actions.push({
            name: "Generate purchase order document"
          })
        }
      }
    } else if (orderStatus && supplier && createdAt && poName.length > 0) {
      // Create a new purchase order
      actions.push({
        name: "Save purchase order"
      })
    }

    setActions(actions)
  }, [purchaseOrder, orderStatus, supplier, createdAt, poName, existingPO, items.length])

  useEffect(() => {
    const initPurchaseOrders = async() => {
      const filter = {}
      if (createdAt)
        filter.createdAt = {ge: createdAt}

      if (supplier)
        filter.supplier = {eq: supplier.name}

      if (orderStatus)
        filter.orderStatus = {eq: orderStatus.name}
      
      filter.delivered = {eq: delivered}
      
      const existingPOs = await fetchPurchaseOrders(filter)
      setPos([{name: "Create a new purchase order"}, ...existingPOs])
    }

    initPurchaseOrders()

  }, [poUpdatedTrigger, createdAt, supplier, orderStatus, delivered])

  const resetPO = () => {
    setExistingPO(false)
    setPoID(null)
    setPOName('')
    setSupplier(null)
    setOrderStatus({name: "draft"})
    setItems([])
    setCreatedAt(null)
    setCreatedBy(staffName)
    setAction(null)
  }

  useEffect(() => {
    const initPurchaseOrder = async() => {
      if (purchaseOrder === null)
        return

      if (purchaseOrder.name === "Create a new purchase order") {
        resetPO()
      } else {
        setExistingPO(true)
        setPoID(purchaseOrder.id)
        setPOName(purchaseOrder.name)
        setSupplier({name: purchaseOrder.supplier})
        setOrderStatus({name: purchaseOrder.orderStatus})
        setDelivered(purchaseOrder.delivered)
        const items = await fetchPOItems(purchaseOrder.id)
        setItems(items)
        setCreatedAt(purchaseOrder.createdAt)
        setCreatedBy(purchaseOrder.createdBy)
      }
    }
    initPurchaseOrder()
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [purchaseOrder])

  const savePurchaseOrder = async() => {
    const poData = {
      name: poName,
      supplier: supplier.name,
      orderStatus: orderStatus.name,
      amount: amount,
      createdBy: createdBy
    }
    if (existingPO) {
      poData.updatedBy = staffName
      poData.id = poID
      await updatePO(poData)
    } else {
      await createPO(poData)
    }
    
    setPoUpdatedTrigger(!poUpdatedTrigger)
    setAction('')
    savePOItems(items)

    if (!existingPO)
    {
      setPurchaseOrder(null)
      resetPO()
    }
  }

  const updatePOStatus = async() => {
    const poData = {
      id: poID,
      orderStatus: orderStatus.name,
      updatedBy: staffName
    }
    await updatePO(poData)
    setPoUpdatedTrigger(!poUpdatedTrigger)
    setAction('')
  }

  const duplicatePO = async() => {
    const poData = {
      name: poName,
      supplier: supplier.name,
      orderStatus: "draft",
      amount: amount,
      delivered: false,
      createdBy: staffName
    }
    const newID = await createPO(poData)
    let newItems = [...items]
    newItems.forEach(item => {
      item.purchaseOrderID = newID
    })
    duplicateItemsForNewPO(items, newID)
  }

  const handleAttachment = async() => {
    const fileName = `po-${poName}-${moment(new Date()).format("YYYY-MM-DD")}-${docType}.pdf`
    await uploadDoc('doc-attachment', fileName)
    updateInvoiceReceiptDoc(poID, docType, fileName)
    let updatedPO = {...purchaseOrder}
    if (docType === 'invoice')
      updatedPO.invoiceDoc = fileName
    else
      updatedPO.receiptDoc = fileName
    setPurchaseOrder(updatedPO)
    addReport(poName, amount, staffName, fileName)
  }

  useEffect(() => {
    const doAction = async() => {
      if (action === null)
        return

      switch (action.name) {
        case 'Save purchase order':
          await savePurchaseOrder()
          break
        case 'Update purchase order status':
          await updatePOStatus()
          break
        case "Generate purchase order document":
          savePOItems(items)
          const poDoc = await genPOPDF(purchaseOrder.name, staffName, createdAt, supplier.name, amount, items)
          updatePODoc(poID, poDoc)
          let updatedPO = {...purchaseOrder}
          updatedPO.orderDoc = poDoc
          setPurchaseOrder(updatedPO)
          addReport(poName, amount, staffName, poDoc)
          break
        case "Duplicate a new purchase order from the selected one":
          duplicatePO()
          break
        case "Mark delivered and update store quantity":
          await markPOItemsDelivered(poID, items)
          purchaseOrder.delivered = true
          break
        case "Attach invoice document":
          savePOItems(items)
          setDocType('invoice')
          setAttachmentTrigger(!attachmentTrigger)
          break
        case "Attach receipt document": 
          savePOItems(items)
          setDocType('receipt')
          setAttachmentTrigger(!attachmentTrigger)
          break
        default:
      }

      setAction(null)
    }

    doAction()
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [action])

  return (
    <>
      <Grid container justify="space-between" alignItems='center' spacing={3}>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={purchaseOrder}
            setOption={setPurchaseOrder}
            options={pos}
            label="Select a purchase order"
            placeholder="purchase order"   
          />
        </Grid>
        <Grid item align="left" md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="po-date-picker"
              label="PO date / after search date"
              value={createdAt}
              onChange={(date) => setCreatedAt(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>  
        </Grid>        
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            option={supplier}
            setOption={setSupplier}
            options={suppliers}
            label="Product supplier"
            placeholder="supplier"
          />
        </Grid>   
        <Grid item align="left" md={2}>
          <DropdownInput
            required
            option={orderStatus}
            setOption={setOrderStatus}
            options={orderStatuses}
            label="PO status"
            placeholder="status"
          />
        </Grid>
        <Grid item align="right" md={1}>
          <FormGroup>
            <FormControlLabel 
              control={<Switch checked={delivered} onChange={() => setDelivered(!delivered)} />} 
              label="delivered" 
            />
          </FormGroup>             
        </Grid>            
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={3}>
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Purchase order name"
            type="text"
            fullWidth
            value={poName}
            onChange={(event) => setPOName(event.target.value)}
          />          
        </Grid>  
        <Grid item align="left" md={3}>
          <TextField
            margin="dense"
            label="Amount"
            type="text"
            fullWidth
            value={amount}
            disabled
          />          
        </Grid>
        <Grid item align="left" md={2}>
          <Button color='primary' 
            href={purchaseOrder ? `${docsPath}${purchaseOrder.orderDoc}` : null} 
            target="_blank" 
            disabled={purchaseOrder ? !purchaseOrder.orderDoc : true}
          >
            Purchase order
          </Button>
        </Grid>          
        <Grid item align="left" md={2}>
          <Button color='primary' 
            href={purchaseOrder ? `${docsPath}${purchaseOrder.invoiceDoc}` : null} 
            target="_blank" 
            disabled={purchaseOrder ? !purchaseOrder.invoiceDoc : true}
          >
            Invoice
          </Button>
        </Grid>          
        <Grid item align="left" md={2}>
          <Button color='primary' 
            href={purchaseOrder ? `${docsPath}${purchaseOrder.receiptDoc}` : null} 
            target="_blank" 
            disabled={purchaseOrder ? !purchaseOrder.receiptDoc : true}
          >
            Receipt
          </Button>
        </Grid>          
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={3}>
        <Grid item align="left" md={3}>
          {`Created by ${purchaseOrder ? purchaseOrder?.createdBy : ''}`}
        </Grid>
        <Grid item align="left" md={3}>
          {`Updated by ${purchaseOrder ? (purchaseOrder.updatedBy ? purchaseOrder.updatedBy : '') : ''}`}
        </Grid>
        <Grid item align="left" md={6}>
          <DropdownInput
            option={action}
            setOption={setAction}
            disableClearable={true}
            disabled={purchaseOrder === null}
            options={actions}
            label="Actions"
            placeholder="action"
          />
        </Grid>
      </Grid>
      <PopupAttachment triggerOpen={attachmentTrigger} docType={docType} handleAttachment={handleAttachment}/>
    </>
  )
}

export default PurchaseOrderFilter