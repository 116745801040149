import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CentreAlignedButton from './centered-button'
import { listProductsByFilter, removeProducts } from '../helpers/product-graphql' 
import { fetchAudits, addProductsToAudit } from '../helpers/audit-graphql'
import { fetchPurchaseOrders, addProductsToPurchaseOrder } from '../helpers/po-graphql'
import DropdownInput from './dropdown-input'
import PopupSelection from './popup-selection'

const InventoryFilter = ({setProducts, stores, suppliers, categories, removeCallback, productIDs}) => {
  const [category, setCategory] = useState(null)
  const [productCode, setProductCode] = useState("")
  const [store, setStore] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [description, setDescription] = useState("")
  const [operation, setOperation] = useState(null)
  const [popupTitle, setPopupTitle] = useState("")
  const [popupOptions, setPopupOptions] =useState([])
  const [popupOption, setPopupOption] =useState('')
  const [triggerOpen, setTriggerOpen] = useState(false)
  const [popupOptionsObj, setPopupOptionsObj] = useState()

  const operations = [
    {
      name: "Delete"
    },
    {
      name: "Add to an audit list"
    },
    {
      name: "Add to a purchase order"
    }
  ]

  useEffect(() => {
    const initOperation = async() => {
      if (operation === null)
        return

      if (operation.name === "Delete")
        removeProducts(productIDs, removeCallback)
      else if (operation.name === "Add to an audit list") {
        const audits = await fetchAudits()
        setPopupTitle("Select an audit to add products to")
        let popupOptionsObj = {}
        let popupOptionList = ['']
        audits.forEach(audit => {
          popupOptionList.push(audit.name)
          popupOptionsObj[audit.name] = audit.id
        })
        setPopupOptionsObj(popupOptionsObj)
        setPopupOptions(popupOptionList)
        setTriggerOpen(!triggerOpen)
        setPopupOption('')
      } else {
        const filter = {
          orderStatus: {eq: "draft"}
        }
        const pos = await fetchPurchaseOrders(filter)
        setPopupTitle("Select a purchase order to add products to")
        let popupOptionsObj = {}
        let popupOptionList = ['']
        pos.forEach(po => {
          popupOptionList.push(po.name)
          popupOptionsObj[po.name] = po.id
        })
        setPopupOptionsObj(popupOptionsObj)
        setPopupOptions(popupOptionList)
        setTriggerOpen(!triggerOpen)
        setPopupOption('')
      }
    }
    initOperation()
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [operation])
      
  const fetchProducts = async() => {
    let filter = {
      obsolete: {eq: false}
    }

    if (productCode.length > 0) {
      filter.productCode = {eq: productCode}
    }  
    else {
      if (category)
        filter.category = {eq: category.name}
      if (store) {
        filter.storeID = {eq: store.id}
      }
      if (supplier)
        filter.supplier = {contains: supplier.name}
      if (description.length > 0)
        filter.description = {contains: description}
    }

    listProductsByFilter(filter, setProducts)
  }

  const handleSelection= () => {
    if (operation.name === "Add to an audit list") {
      const auditID = popupOptionsObj[popupOption]
      addProductsToAudit(productIDs, auditID)
    } else {
      const poID = popupOptionsObj[popupOption]
      addProductsToPurchaseOrder(productIDs, poID)
    } 
  }

  return (
    <>
      <Grid container justify="space-between" alignItems='center' spacing={10}>
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Product code"
            type="text"
            fullWidth
            value={productCode}
            onChange={(event) => setProductCode(event.target.value.trim())}
          />          
        </Grid>  
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />          
        </Grid>  
        <Grid item align="left" md={4}>
          <CentreAlignedButton buttonText='Search' onClick={fetchProducts}/>
        </Grid>  
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={10}>
        <Grid item align="left" md={3}>
          <DropdownInput
            option={category}
            setOption={setCategory}
            options={categories}
            label="Product category"
            placeholder="category"
          />
        </Grid>       
        <Grid item align="left" md={3}>
          <DropdownInput
            option={supplier}
            setOption={setSupplier}
            options={suppliers}
            label="Product supplier"
            placeholder="supplier"
          />
        </Grid>   
        <Grid item align="left" md={3}>
          <DropdownInput
            option={store}
            setOption={setStore}
            options={stores}
            label="Main Storage"
            placeholder="store"
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            option={operation}
            setOption={setOperation}
            disableClearable={true}
            disabled={productIDs.length === 0}
            options={operations}
            label="Selected products"
            placeholder="operation"
          />
        </Grid>
      </Grid>
      <PopupSelection
        triggerOpen={triggerOpen}
        title={popupTitle}
        options={popupOptions}
        option={popupOption}
        setOption={setPopupOption}
        handleSelection={handleSelection}
      />
    </>
  )
}

export default InventoryFilter