import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Content from './content'
import titleImg from '../images/bg10.jpg'
import InventoryInput from './inventory-input'
import InventoryBrowser from './inventory-browser'
import InventoryAudit from './inventory-audit'
import InventoryPurchaseOrder from './inventory-po'
import supplierList from '../constants/supplier-list.json'
import categories from '../constants/product-categories.json'
import { fetchStores }  from '../helpers/product-graphql'

const Inventory = () => {
  const [mode, setMode] = useState('browse')
  const [product, setProduct] = useState(null)
  const [products, setProducts] = useState([])
  const [index, setIndex] = useState(null)  // The index of the product that is sent to the "input" mode for update  
  const [stores, setStores] = useState({})
  const suppliers = supplierList.sort((a, b) => {
    if (a.name < b.name)
      return -1
    else if (a.name > b.name)
      return 1
    else 
      return 0
  })

  useEffect(() => {
    fetchStores(setStores)
  }, [])

  const updateCallback = (updatedProduct) => {
    let existingProducts = [...products]
    existingProducts.splice(index, 1, updatedProduct)
    setProducts(existingProducts)
  }

  return (
    <Content title='Inventory' titleImg={titleImg} tagline="Stock level monitoring and reorder">
      <FormControl component="fieldset">
        <RadioGroup 
          row
          aria-label="inventory-mode" 
          name="inventory-mode" 
          value={mode} 
          onChange={(event) => setMode(event.target.value)}
        >
          <FormControlLabel value="browse" control={<Radio />} label="Browse inventory items" />
          <FormControlLabel value="input" control={<Radio />} label="Enter a new item / update an existing item" />
          <FormControlLabel value="audit" control={<Radio />} label="Stock level audit" />
          <FormControlLabel value="po" control={<Radio />} label="Purchase order" />
        </RadioGroup>
      </FormControl>      
      {mode === 'browse' && 
      <InventoryBrowser
        setProduct={setProduct} 
        setMode={setMode} 
        products={products} 
        setProducts={setProducts} 
        setIndex={setIndex}
        suppliers={suppliers}
        categories={categories}
        stores={stores}/>}
      {mode === 'input' && 
      <InventoryInput
        product={product}
        updateCallback={updateCallback}
        suppliers={suppliers}
        categories={categories}
        stores={stores}
      />}
      {mode === 'audit' && <InventoryAudit/>}
      {mode === 'po' && <InventoryPurchaseOrder suppliers={suppliers}/>}
    </Content>
  )
}

export default Inventory