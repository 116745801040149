import { Auth } from 'aws-amplify'
const isBrowser = typeof window !== `undefined`

/*
*   All attributes in AWS CognitoUser object: 
*   username: set after successful login and cleared after successful logout
*   patientName, patientId, patientIndex, bpPatientId: set when patient is selected (in patients.js) and cleared after appointment is booked (in book.js) 
*   appId, drId, appTime, appDuration: set when an appointment is selected (in appointment-browser.js) and cleared after appointment is booked (in book.js)
*   checkingBookingStatus: set after booking to display appointments in my-account page. cleared after my-account page mounts. 
*
*   newPatientId: 
*
*/
export const setStaff = staff => {
  if (isBrowser) 
    (window.localStorage.aspireMedCentreStaff = JSON.stringify(staff))
}

export const getStaff = () => {
  if (isBrowser && window.localStorage.aspireMedCentreStaff) {
    let staff = JSON.parse(window.localStorage.aspireMedCentreStaff)
    return staff ? staff : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const staff = getStaff()
  if (staff) return !!staff.username
}

export const getCurrentStaff = () => isBrowser && getStaff()

export const logout = async () => {
  try {
    await Auth.signOut()
  } catch (error) {
      console.log('error signing out: ', error)
  }  

  if (!isBrowser) return
  setStaff({})
}

export const isAdmin = () => {
  if (!isBrowser) return false

  const staff = getStaff()
  // if (staff) return staff["custom:staff"] === "admin" || staff["custom:staff"] === "manager"

  return staff.groups?.includes("Admin")
}

export const isManager = () => {
  if (!isBrowser) return false

  const staff = getStaff()
  // if (staff) return staff["custom:staff"] === "manager"

  return staff.groups?.includes("Manager")
}
