import { API, graphqlOperation } from 'aws-amplify'
import { getProduct, listProducts }  from '../graphql/product-queries'
import { listStores, listShelfs }  from '../graphql/queries'
import { createProduct, updateProduct }  from '../graphql/mutations'

export const fetchStores = async(setStores) => {
  try {
    const result = await API.graphql({
      query: listStores,
      variables: {
        limit: 65536 // This should be the number of items in the table -> No pagination
      }        
    })
    const sortedStores = result.data.listStores.items.sort((a, b) => {
      if (a.name < b.name)
        return -1
      else if (a.name > b.name)
        return 1
      else 
        return 0
    })
    setStores(sortedStores.map(store => {
      return {
        name: store.name,
        id: store.id
      }
    }))
  } catch (err) {
    console.log('Amplify listStores error...: ', err)
  }
}

export const fetchShelves = async(setShelves) => {
  try {
    const result = await API.graphql({
      query: listShelfs,
      variables: {
        limit: 65536 // This should be the number of items in the table -> No pagination
      }        
    })
    const sortedShelves = result.data.listShelfs.items.sort((a, b) => {
      if (a.name < b.name)
        return -1
      else if (a.name > b.name)
        return 1
      else 
        return 0
    })
    setShelves(sortedShelves.map(shelf => {
      return {
        name: shelf.name,
        id: shelf.id
      }
    }))
  } catch (err) {
    console.log('Amplify listShelfs error...: ', err)
  }
}

export const fetchProduct = async(id, setProduct) => {
  try {
    const result = await API.graphql(graphqlOperation(getProduct, {
      id: id
    }))

    const product = result.data.getProduct
    setProduct(product)
  } catch (err) {
    console.log('Amplify getProduct error...: ', err)
    return        
  }
}

export const listProductsByCode = async(productCode, initProduct, reset) => {
  try {
    const result = await API.graphql({
      query: listProducts,
      variables: {
        filter: {
          productCode: {eq: productCode},
          obsolete: {eq: false}
        },
        limit: 65536
      }        
    })
    const oldProduct = result.data.listProducts.items[0]
    if (oldProduct) 
      initProduct(oldProduct)
    else 
      reset()

  } catch (err) {
    console.log('Amplify listProducts error...: ', err)
    return        
  }
}

export const listProductsByFilter = async(filter, setProducts) => {
  try {
    const result = await API.graphql({
      query: listProducts,
      variables: {
        filter: filter,
        limit: 65536
      }        
    })
    setProducts(result.data.listProducts.items)
  } catch (err) {
    console.log('Amplify listProducts error...: ', err)
    alert('Amplify listProducts error...: ', err)
    return
  }
}

export const createOrUpdateProduct = async(productID, productData, existingProduct, updateRequestFromBrowser, setUpdateRequestFromBrowser, updateCallback) => {
  try {
    if (existingProduct) {
      productData.id = productID
      const result = await API.graphql(graphqlOperation(updateProduct, {
        input: productData
      }))
      if (updateRequestFromBrowser) {
        updateCallback(result.data.updateProduct)
        setUpdateRequestFromBrowser(false)
      }
    } else {
      await API.graphql(graphqlOperation(createProduct, {
        input: productData
      }))
    }

    alert('Product saved successfully')
  } catch (err) {
    console.log('Amplify createProduct/updateProduct error...: ', err)
    alert('Product failed to save')
    return        
  }
}

export const removeProducts = async(productIDs, removeCallback) => {
  if (productIDs.length === 0)
    return
    
  try {
    Promise.allSettled(productIDs.map(id => {
      return API.graphql(graphqlOperation(updateProduct, {
        input: {
          id: id,
          obsolete: true
        }
      }))
    }))
    .then(() => {
      removeCallback()
      alert('Products removed successfully')
    })
  } catch (err) {
    console.log('Amplify updateProduct error...: ', err)
    alert('Products failed to remove')
    return        
  }
}