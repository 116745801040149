import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Content from '../components/content'
import titleImg from '../images/bg2.jpg'
import PaymentInput from './payment-input'
import PaymentBrowser from './payment-browser'

const Payment = () => {
  const [mode, setMode] = useState('input')
  const [invoice, setInvoice] = useState(null)
  const [invoices, setInvoices] = useState([])
  const [index, setIndex] = useState(null)  // The index of the invoice that is sent to the "input" mode for update

  const updateCallback = (updatedInvoice) => {
    let existingInvoices = [...invoices]
    existingInvoices.splice(index, 1, updatedInvoice)
    setInvoices(existingInvoices)
  }

  return (
    <Content title='Non Medicare Payment Tracker'  titleImg={titleImg} tagline="Insurance claims and EFTPOS via Tyro terminal">
      <FormControl component="fieldset">
        <RadioGroup 
          row
          aria-label="payment-mode" 
          name="payment-mode" 
          value={mode} 
          onChange={(event) => setMode(event.target.value)}
        >
          <FormControlLabel value="input" control={<Radio />} label="Enter a new invoice / update an existing invoice" />
          <FormControlLabel value="browse" control={<Radio />} label="Browse invoices" />
        </RadioGroup>
      </FormControl>      
      {mode === 'input' && <PaymentInput invoice={invoice} updateCallback={updateCallback}/>}
      {mode === 'browse' && 
      <PaymentBrowser 
        setInvoice={setInvoice} 
        setMode={setMode} 
        invoices={invoices} 
        setInvoices={setInvoices} 
        setIndex={setIndex}/>}
    </Content>
  )
}

export default Payment