import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import CentreAlignedButton from './centered-button'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { fetchStaffs } from '../helpers/timesheet-graphql'
import DropdownInput from './dropdown-input'
import { filterReports } from '../helpers/report-graphql'
import getUTC from '../helpers/getutc'

const DocumentFilter = ({setItems}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [staff, setStaff] = useState(null)
  const [staffs, setStaffs] = useState([])
  const [tag, setTag] = useState('')
  const [amount, setAmount] = useState('')
  
  //Get staff list
  useEffect(() => {
    const initStaffs = async() => {
      const staffList = await fetchStaffs()
      setStaffs(staffList)
    }

    initStaffs()
  }, [])

  const search = async() => {
    let filter = {}
    if (staff)
      filter.createdBy = {eq: staff.name}
    if (tag.length > 0)
      filter.tag = {contains: tag}
    if (amount.length > 0)
      filter.amount = {eq: amount}

    if (startDate && endDate) {
      const dateStart = new Date(startDate)
      const dateEnd = new Date(endDate)
      dateStart.setHours(0)
      dateEnd.setHours(23)
      filter.createdAt = {between: [getUTC(dateStart), getUTC(dateEnd)]}
    } else if (startDate) {
      const dateStart = new Date(startDate)
      dateStart.setHours(0)
      filter.createdAt = {gt: getUTC(dateStart)}
    } else if (endDate) {
      const dateEnd = new Date(endDate)
      dateEnd.setHours(23)
      filter.createdAt = {lt: getUTC(dateEnd)}
    }

    const docs = await filterReports(filter)
    setItems(docs)
  }
  
  return (
    <>
      <Grid container justify="space-between" alignItems='center' spacing={3}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="start-date-picker"
              label="Select start creation date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item align="left" md={4}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="end-date-picker"
              label="Select end creation date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={4}>
          <CentreAlignedButton 
            buttonText='Search' 
            onClick={search} 
            disabled={startDate === null && endDate === null && staff === null && tag.length === 0 && amount.length === 0}
          />          
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems='center' spacing={3}>
        <Grid item align="left" md={4}>
          <DropdownInput option={staff} setOption={setStaff} options={staffs} label='Created by' placeholder='staff'/>
        </Grid>
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Tag"
            type="text"
            fullWidth
            value={tag}
            onChange={(event) => setTag(event.target.value)}
          />
        </Grid>
        <Grid item align="left" md={4}>
          <TextField
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(event) => setAmount(event.target.value)}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DocumentFilter