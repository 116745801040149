import React, { useState, useEffect, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DropDownSelection from './dropdown-selection'

export default function PopupSelection({triggerOpen, title, options, option, setOption, handleSelection}) {
  const [open, setOpen] = useState(false)
  const didMountRef = useRef(false)
  
  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true)
    }
    else {
      didMountRef.current = true
    }
  }, [triggerOpen])

  const closeDialog = () => {
    setOpen(false)      
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={closeDialog}>
        <DialogTitle>{title}</DialogTitle>        
        <DialogContent>    
          <DropDownSelection
            value={option}
            handleChange={setOption}
            items={options}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpen(false)}
            color="primary" 
            fullWidth
          >
            Cancel
          </Button>
          <Button 
            onClick={() => {
              setOpen(false)
              handleSelection()}
            } 
            color="primary" 
            fullWidth
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}