const getUTC = (time) => {
  const year = time.getUTCFullYear()
  const month = (time.getUTCMonth() + 1).toString()
  const date = time.getUTCDate().toString()
  const hours = time.getUTCHours().toString()
  const monthString = month.length === 1 ? `0${month}` : month
  const dateString = date.length === 1 ? `0${date}` : date
  const hoursString = hours.length === 1 ? `0${hours}` : hours

  return `${year}-${monthString}-${dateString}T${hoursString}:00:00`
}

export default getUTC