import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Content from '../components/content'
import titleImg from '../images/bg7.jpg'
import TimeSheetInput from './timesheet-input'
import TimeSheetSummary from './timesheet-summary'

const TimeSheet = () => {
  const [mode, setMode] = useState('input')

  return (
    <Content title='Time Sheet'  titleImg={titleImg} tagline="Don't forget to log before you finish your shift">
      <FormControl component="fieldset">
        <RadioGroup 
          row
          aria-label="timesheet-mode" 
          name="timesheet-mode" 
          value={mode} 
          onChange={(event) => setMode(event.target.value)}
        >
          <FormControlLabel value="input" control={<Radio />} label="Log your time sheet" />
          <FormControlLabel value="summary" control={<Radio />} label="View time sheet summary" />
        </RadioGroup>
      </FormControl>      
      {mode === 'input' && <TimeSheetInput/>}
      {mode === 'summary' && <TimeSheetSummary/>}
    </Content>
  )
}

export default TimeSheet