import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CentreAlignedButton from './centered-button'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { getStaff } from './auth//app-staff'
import { newTimeSheet, modifyTimeSheet, getTimeSheets } from '../helpers/timesheet-graphql'

const TimeSheetInput = () => {
  const [arrival, setArrival] = useState(null)
  const [departure, setDeparture] = useState(null)
  const [id, setId] = useState(false)
  const [staffID] = useState(getStaff().username)

  const setDate = async(date) => {
    const dateStart = new Date(date)
    const dateEnd = new Date(date)
    dateStart.setHours(0)
    dateEnd.setHours(23)
    const existingTimeSheet = await getTimeSheets(staffID, dateStart, dateEnd)
    if (existingTimeSheet.length === 1) {
      setId(existingTimeSheet[0].id) 
      setArrival(new Date(existingTimeSheet[0].arrivalTime))
      setDeparture(new Date(existingTimeSheet[0].departureTime))
    } else {
      setArrival(date)
      setDeparture(date)
    }
  }

  const addTimeSheet = async () => {
    if (!id) 
      await newTimeSheet(staffID, arrival, departure)
    else 
      await modifyTimeSheet(id, staffID, arrival, departure)

    alert('Time sheet saved successfully')
  }  

  return (
    <Grid container justify="space-between" alignItems='center'>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker"
          label="Select time sheet date"
          value={arrival}
          onChange={(date) => setDate(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="arrival-time-picker"
          label="Select arrival time"
          value={arrival}
          minutesStep={5}
          onChange={(time) => setArrival(time)}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="departure-time-picker"
          label="Select departure time"
          value={departure}
          minutesStep={5}
          onChange={(time) => setDeparture(time)}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </MuiPickersUtilsProvider>
      <CentreAlignedButton buttonText='Submit' onClick={addTimeSheet} disabled={departure < arrival}/>
    </Grid>
  )
}

export default TimeSheetInput