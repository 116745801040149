import React from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import moment from 'moment'


const TimeSheetDisplay = ({items}) => {
  function getRegularSum(total, ts) {
    return total + ts.regularHours
  }
  function getSatAfter3Sum(total, ts) {
    return total + (ts.penaltyType === 1 ? ts.penaltyHours : 0)
  }
  function getPubSum(total, ts) {
    return total + (ts.penaltyType === 2 ? ts.penaltyHours : 0)
  }

  const totalRegular = items.reduce(getRegularSum, 0)
  const totalSatAfter3 = items.reduce(getSatAfter3Sum, 0)
  const totalPub = items.reduce(getPubSum, 0)
  const dayWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  return (
    <Container style={{marginTop: 30}}>
      <Grid container>
        <Grid item align="right" md={2}>
          date
        </Grid>
        <Grid item align="right" md={2}>
          arrival time
        </Grid>
        <Grid item align="right" md={2}>
          departure time
        </Grid>
        <Grid item align="right" md={1}>
          regular hours
        </Grid>
        <Grid item align="right" md={1}>
          penalty hours
        </Grid>
        <Grid item align="left" md={4} style={{paddingLeft: 30}}>
          penalty type
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {items.map(item => (
        <Grid container key={item.id} alignItems="center" style={{marginBottom: 4}}>
          <Grid item align="right" md={2}>
            {`${moment(item.arrivalTime).format("YYYY-MM-DD")} ${dayWeek[new Date(item.arrivalTime).getDay()]}`}
          </Grid>
          <Grid item align="right" md={2}>
            {moment(item.arrivalTime).format("h:mm A")}
          </Grid>
          <Grid item align="right" md={2}>
            {moment(item.departureTime).format("h:mm A")}
          </Grid>
          <Grid item align="right" md={1}>
            {item.regularHours > 0 ?
            item.regularHours.toFixed(2)
            :
            null}
          </Grid>
          <Grid item align="right" md={1}>
            {item.penaltyHours > 0 ?
            item.penaltyHours.toFixed(2)
            :
            null}
          </Grid>
          <Grid item align="left" md={4} style={{paddingLeft: 30}}>
            {item.penaltyNote}
          </Grid>
        </Grid>
      ))}
      <hr style={{marginTop: 30}}/>
      <Grid container>
        <Grid item align="right" md={4}>
          Total regular hours
        </Grid>
        <Grid item align="right" md={4}>
          Total Saturday after 3:00 pm hours
        </Grid>
        <Grid item align="right" md={4}>
          Total Sunday/public holiday hours
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      <Grid container>
        <Grid item align="right" xs={4} md={4}>
          {totalRegular.toFixed(2)}
        </Grid>
        <Grid item align="right" xs={4} md={4}>
          {totalSatAfter3.toFixed(2)}
        </Grid>
        <Grid item align="right" xs={4} md={4}>
          {totalPub.toFixed(2)}
        </Grid>
      </Grid>
    </Container>
  )
}

export default TimeSheetDisplay