import { API } from 'aws-amplify'
import { listNswPublicHolidays } from '../graphql/queries'

const getNSWPubHolidays = async(year) => {
  try {
    const result = await API.graphql({
      query: listNswPublicHolidays,
      variables: {
        filter: {
          id: {beginsWith: year}
        },
        limit: 65536
      }        
    })
    const holidays = result.data.listNSWPublicHolidays.items
    return holidays
  } catch (err) {
    console.log('Amplify listNswPublicHolidays error...: ', err)
  }
}

export default getNSWPubHolidays