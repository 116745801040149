import { API, graphqlOperation } from 'aws-amplify'
import { timeSheetByStaffDate, listStaffs }  from '../graphql/queries'
import { createTimeSheet, updateTimeSheet }  from '../graphql/mutations'
import getUTC from '../helpers/getutc'

export const fetchStaffs = async() => {
  try {
    const result = await API.graphql({
      query: listStaffs,
      variables: {
        filter: {
          active: {eq: true}
        }
      }
    })

    const staffList = result.data.listStaffs.items.map((item) => {
      return {
        staffID: item.id,
        name: item.name
      }
    })

    return staffList
  } catch (err) {
    console.log('Amplify listStaffs error...: ', err)
  }      
}

export const getTimeSheets = async(staffID, dateStart, dateEnd) => {
  try {
    const result = await API.graphql({
      query: timeSheetByStaffDate,
      variables: {
        staffID: staffID,
        arrivalTime: {between: [getUTC(dateStart), getUTC(dateEnd)]}
      }        
    })
    return result.data.timeSheetByStaffDate.items
  } catch (err) {
    console.log('Amplify timeSheetByStaffDate error...: ', err)
  }
}

export const newTimeSheet = async(staffID, arrival, departure) => {
  try {
    await API.graphql(graphqlOperation(createTimeSheet, {
      input: {
        staffID: staffID,
        arrivalTime: arrival,
        departureTime: departure
      }
    }))

  } catch (err) {
    console.log('Amplify createTimeSheet error...: ', err)
  }
}

export const modifyTimeSheet = async(id, staffID, arrival, departure) => {
  try {
    await API.graphql(graphqlOperation(updateTimeSheet, {
      input: {
        id: id,
        staffID: staffID,
        arrivalTime: arrival,
        departureTime: departure
      }
    }))

  } catch (err) {
    console.log('Amplify updateTimeSheet error...: ', err)
  }
}