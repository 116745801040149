import axios from 'axios'
import moment from 'moment'

export async function genAuditPDF(staffName, auditName, auditComment, products) {
  const auditDate = moment(new Date()).format("YYYY-MM-DD")
  const heading = `${auditName} audit report performed by ${staffName} on ${auditDate}`
    const reportItems = products.map(p => `
    <tr>
      <td>${p.product.description}</td>
      <td>${p.expiry ? moment(p.expiry).format("YYYY-MM-DD") : ''}</td>
      <td>${p.qty ? p.qty : ''}</td>
      <td>${p.shelf ? p.shelf.name : ''}</td>
    </tr>
  `)
  const comment = `Audit comment: ${auditComment}`
  const content = `
    <!DOCTYPE html>
    <html>
    <style>
    table, th, td {
      border:1px solid black;
    }
    </style>
    <body>
      <h4>${heading}</h4>
      <p>${comment}</p>
      <table style="width:100%">
        <tr>
          <th>Product name</th>
          <th>Expiry</th>
          <th>Qty</th>
          <th>Shelf</th>
        </tr>
        ${reportItems.join('')}
      </table>
    </body>
    </html>
  `
  try {
    const auditDocName = `audit-${auditName.replace(/[^a-zA-Z0-9]+/g, "-")}-${auditDate}.pdf`
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: process.env.GATSBY_PDF_GENERATOR,
      data: {
        content: content,
        s3Key: `public/${auditDocName}`
      }
    }
    await axios(config)
    alert("Audit report generated successfully")
    return auditDocName
  } catch (error) {
    console.error(error)
  }
}

export async function genPOPDF(poName, staffName, createdAt, supplier, amount, items) {
  const poDate = moment(createdAt).format("YYYY-MM-DD")
  const heading = "Aspire Medical Centre Eastwood purchase order"
  const createdBy = `Created by: ${staffName}`
  const poSupplier = `Supplier: ${supplier}`
  const total = `Order total: $${amount}`
    const poItems = items.map(item => `
    <tr>
      <td>${item.product.productCode}</td>
      <td>${item.product.description}</td>
      <td>${item.product.package}</td>
      <td>${item.qty}</td>
      <td>${item.price}</td>
    </tr>
  `)
  const content = `
    <!DOCTYPE html>
    <html>
    <style>
    table, th, td {
      border:1px solid black;
    }
    </style>
    <body>
      <h4>${heading}</h4>
      <p>Date: ${poDate}</p>
      <p>${createdBy}</p>
      <p>${poSupplier}</p>
      <p>${total}</p>
      <table style="width:100%">
        <tr>
          <th>Product code</th>        
          <th>Product name</th>
          <th>Package</th>
          <th>Qty</th>
          <th>Price</th>
        </tr>
        ${poItems.join('')}
      </table>
    </body>
    </html>
  `
  try {
    const poDocName = `po-${poName.replace(/[^a-zA-Z0-9]+/g, "-")}-${poDate}.pdf`
    const config = {
      method: 'post',
      headers: {"Content-Type": "application/json"},
      url: process.env.GATSBY_PDF_GENERATOR,
      data: {
        content: content,
        s3Key: `public/${poDocName}`
      }
    }
    await axios(config)
    alert("Purchase order document generated successfully")
    return poDocName
  } catch (error) {
    console.error(error)
  }
}
