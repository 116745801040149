import React, { useState, useEffect, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DropdownInput from './dropdown-input'
import { fetchShelves }  from '../helpers/product-graphql'
import { updateProductInAudit, deleteProductInAudit, getProductFromStore }  from '../helpers/audit-graphql'

export default function AuditProductEdit({triggerOpen, products, setProducts, index}) {
  const [open, setOpen] = useState(false)
  const [id, setId] = useState(null)
  const [shelf, setShelf] = useState(null)
  const [shelves, setShelves] = useState([])
  const [expiry, setExpiry] = useState(null)
  const [qty, setQty] = useState(0)
  const [fromStoreQty, setFromStoreQty] = useState(0)
  const [title, setTitle] = useState('')
  const [productID, setProductID] = useState(null)
  const didMountRef = useRef(false)
  
  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true)
      const product = products[index]
      setId(product.id)
      setFromStoreQty(0)
      if (product.expiry)
        setExpiry(product.expiry)
      else
        setExpiry(null)  
      if (product.qty)
        setQty(product.qty)
      else
        setQty(0)
      if (product.shelf)
        setShelf(product.shelf)
      else
        setShelf(null)
      setTitle(product.product.description)
      setProductID(product.product.id)
    }
    else {
      didMountRef.current = true
    }
  }, [triggerOpen, products, index])

  useEffect(() => {
    fetchShelves(setShelves)
  }, [])

  const closeDialog = () => {
    setOpen(false)      
  }

  const handleDelete = () => {
    deleteProductInAudit(id)
    setOpen(false)
    let existingProducts = [...products]
    setProducts(existingProducts.filter(p => p.id !== id))
  }

  const handleSave = () => {
    const storeQty = products[index].product.qty
    const updatedQty = storeQty - fromStoreQty
    if (updatedQty < 0) {
      alert("Store does not have enough stock for your request")
      return
    }

    updateProductInAudit(id, expiry, qty, shelf.id, productID)
    //Update store quantity
    if (fromStoreQty > 0)
      getProductFromStore(productID, updatedQty)

    setOpen(false)
    //Update the list
    let existingProducts = [...products]
    const updatedProduct = {
      ...products[index], 
      qty: qty,
      shelfID: shelf.id,
      expiry: expiry,
      shelf: {
        name: shelf.name
      },
      product: {
        description: products[index].product.description,
        qty: updatedQty
      }
    }
    existingProducts.splice(index, 1, updatedProduct)
    setProducts(existingProducts)
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={closeDialog}>
        <DialogTitle>{title}</DialogTitle>        
        <DialogContent>    
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker"
              label="Select expiry date"
              value={expiry}
              onChange={(date) => setExpiry(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            required
            margin="dense"
            label="Target qty"
            type="number"
            fullWidth
            value={qty}
            onChange={(event) => setQty(event.target.value)}
          />          
          <TextField
            required
            margin="dense"
            label="Qty to fetch from store to maintain the target qty"
            type="number"
            fullWidth
            value={fromStoreQty}
            onChange={(event) => setFromStoreQty(event.target.value)}
          />          
          <DropdownInput
            required
            disableClearable={true}
            option={shelf}
            setOption={setShelf}
            options={shelves}
            label="Select a shelf from the list"
            placeholder="shelf"   
          />   
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDelete}
            color="primary" 
            fullWidth
          >
            Delete
          </Button>
          <Button 
            onClick={handleSave}
            color="primary" 
            fullWidth
            disabled={qty === 0 || shelf === null}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}