import React from 'react'
import UpdateUser from '../components/auth/update-user'
//Need Amplify configuration here in case user navigate here directly.
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

const PageUpdateUser = () => (
  <UpdateUser/>
)

export default PageUpdateUser