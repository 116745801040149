export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productCode
      category
      description
      storeID
      store {
        id
        name
      }
      audits {
        items {
          shelf {
            name
          }
        }
      }
      package
      qty
      minStockQty
      supplier
      obsolete
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productCode
        category
        description
        storeID
        store {
          name
        }
        package
        qty
        minStockQty
        supplier
        obsolete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;