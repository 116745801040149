import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Content from './content'
import titleImg from '../images/bg9.jpg'
import DocumentFilter from './document-filter'
import moment from 'moment'

const DocumentFinder = () => {
  const docsPath = process.env.GATSBY_ASPIRE_ADMIN_DOCS_ROOT
  const [items, setItems] = useState([])

  return (
    <Content title='Document finder'  titleImg={titleImg} tagline='Invoices, receipts, cheques, purchase orders and audit reports'>
      <DocumentFilter setItems={setItems}/>
      <Grid container justify="space-between" style={{marginTop: 40}} spacing={2}>
        <Grid item align="left" md={4}>
          Document
        </Grid>
        <Grid item align="left" md={3}>
          Tag
        </Grid>
        <Grid item align="left" md={1}>
          Amount
        </Grid>
        <Grid item align="left" md={2}>
          Created at
        </Grid>
        <Grid item align="left" md={2}>
          Created by
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {items.map((item, index) => (
        <Grid container justify="space-between" style={{marginTop: 40}} spacing={2} key={index}>
          <Grid item align="left" md={4}>
            <Button color='primary' 
              href={`${docsPath}${item.reportFile}`} 
              target="_blank" 
            >
              {item.reportFile}
            </Button>
          </Grid>          
          <Grid item align="left" md={3}>
            {item.tag}
          </Grid>
          <Grid item align="left" md={1}>
            {item.amount > 0 ? item.amount : null}
          </Grid>
          <Grid item align="left" md={2}>
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </Grid>
          <Grid item align="left" md={2}>
            {item.createdBy}
          </Grid>
        </Grid>
      ))}
    </Content>
  )
}

export default DocumentFinder