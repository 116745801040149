import React, { useState, useContext, useEffect } from "react"
import {
  MDBContainer, MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, 
  MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon
} from "mdbreact"
import { Link, navigate } from 'gatsby'
import CustomNavLink from './custom-link'
import Logo from "../images/AMCE.png"
import './navbar.css'
import { logout, getStaff, isLoggedIn } from './auth/app-staff'
import { LoggedInContext } from './context'

const Navbar = () => {
  const {loggedIn, setLoggedIn} = useContext(LoggedInContext)
  const [isOpen, setIsOpen] = useState(false)
  const greeting = () => {
    const staffInfo = getStaff()
    return `Hello ${staffInfo.given_name[0].toUpperCase()}${staffInfo.family_name[0].toUpperCase()}`
  }

  useEffect(() => {
    if (isLoggedIn())
      setLoggedIn(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps      
  }, [])

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  const signout = () => {
    logout()
    setLoggedIn(false)
    navigate("/")
  }

  return (
    <MDBNavbar light expand="md" id='navbar'>
      <MDBContainer>
        <Link className="waves-effect waves-light" to='/' style={{display: 'flex', alignItems: 'center'}}>
          <img height={100} src={Logo} className="mr-3" alt="Aspire Medical Centre Eastwood logo" />
        </Link>
        <MDBNavbarToggler name="navbar-toggler" onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <CustomNavLink to="/app/time-sheet">Time Sheet</CustomNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <CustomNavLink to="/app/inventory">Inventory</CustomNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <CustomNavLink to="/app/payment">Private Payment</CustomNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <CustomNavLink to="/app/document-finder">Document Finder</CustomNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  {loggedIn ?
                    <span className="mr-2">{greeting()}</span>
                    :
                    <MDBIcon icon="user-lock" />
                  }
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default dropdown-menu">
                  {loggedIn ? 
                    <>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/login-update")}>Login update</MDBDropdownItem>
                      <MDBDropdownItem className="black-text nav-link" onClick={signout}>Sign out</MDBDropdownItem>
                    </> 
                    :
                    <>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/signin")}>Sign in</MDBDropdownItem>
                      <MDBDropdownItem className="black-text nav-link" onClick={() => navigate("/signup")}>Create account</MDBDropdownItem>
                    </>
                  }
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}

export default Navbar
