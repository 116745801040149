import React, { useState, useEffect } from 'react'
import Paper from '@material-ui/core/Paper' 
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import NumberFormat from 'react-number-format'
import { AuthForm, Email, Password, TextInput, ConfirmationCode } from './auth-forms'
import { getStaff, setStaff } from './app-staff'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import {
  CognitoUserPool,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'
import awsConfig from "../../aws-exports"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 10,
    maxWidth: 600,
    padding: 30,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}))

const UpdateUser = () => {
  const staffInfo = getStaff()
  const [email, setEmail] = useState(staffInfo.email)
  const [phoneNumber, setPhoneNumber] = useState(staffInfo.phone_number)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [firstname, setFirstname] = useState(staffInfo.given_name)
  const [surname, setSurname] = useState(staffInfo.family_name)
  const [authCode, setAuthCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState(0)
  const [updateItem, setUpdateItem] = useState('password')
  const [error, setError] = useState('')
  const classes = useStyles()

  useEffect(() => {
    setError('')
  }, [phoneNumber, oldPassword, newPassword, updateItem])
 
  // Auth.updateUserAttributes return Unhandled Rejection (TypeError): user.getSession is not a function
  // const updateAttributes = async e => {
  //   e.preventDefault()
  //   setLoading(true)

  //   try {
  //     const user = await Auth.currentAuthenticatedUser()
  //     await Auth.updateUserAttributes({
  //       user, 
  //       attributes: { 
  //         email: email, 
  //         phone_number: phoneNumber,
  //         given_name: firstname,
  //         family_name: surname
  //       },
  //     })
  //     setLoading(false) 

  //     // email has changed. Verify it.
  //     if (email !== staffInfo.email) {
  //       setStage(1)
  //     } else {
  //       alert('Account update successful')
  //       navigate('/')
  //     }

  //   } catch(err) {
  //     setError(err.message || JSON.stringify(err))
  //     setLoading(false)
  //     console.log('Update user attribute error: ', err)
  //   }
  // }

  const updateAttributes = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const poolData = {
        UserPoolId: awsConfig.aws_user_pools_id,
        ClientId: awsConfig.aws_user_pools_web_client_id        
      }
    
      const userPool = new CognitoUserPool(poolData)
      
      const authenticatedUser = await userPool.getCurrentUser()

      if (authenticatedUser != null) {
        authenticatedUser.getSession(function(err, session) {
          if (err) {
            setLoading(false)
            setError(err.message || JSON.stringify(err))
            return
          }
          // console.log('session validity: ' + session.isValid())
          let attributeList = []
      
          const phone = phoneNumber.replace(/ /g, "")
          let attribute = new CognitoUserAttribute({
            Name: 'phone_number',
            Value: phone,
          })
          attributeList.push(attribute)

          attribute = new CognitoUserAttribute({
            Name: 'email',
            Value: email,
          })
          attributeList.push(attribute)

          attribute = new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstname,
          })
          attributeList.push(attribute)

          attribute = new CognitoUserAttribute({
            Name: 'family_name',
            Value: surname,
          })
          attributeList.push(attribute)
          
          authenticatedUser.updateAttributes(attributeList, function(err, result) {
            if (err) {
              setLoading(false)
              setError(err.message || JSON.stringify(err))
              return
            }
            
            setLoading(false)

            // email has changed. Verify it.
            if (email !== staffInfo.email) {
              setStage(1)
            } else {
              alert('Account update successful')
              navigate('/')
            }
          })
        })
      }
    } catch (err) {
      setError(err.message || JSON.stringify(err))
      setLoading(false)
      console.log('error...: ', err)
    }
  }  

  const confirmEmail = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()    
      cognitoUser.verifyAttribute('email', authCode, {
        onSuccess: function(result) {
          setLoading(false)
          const staffInfo = {
            ...getStaff(),
            phone_number : phoneNumber, 
            email: email, 
            given_name: firstname,
            family_name: surname
          }
          setStaff(staffInfo)

          alert('Account update successful')
          navigate('/')          
        },
        onFailure: function(err) {
          alert(err.message || JSON.stringify(err));
          setLoading(false)
        }
      })
    } catch (err) {
      setError(err.message || JSON.stringify(err))
      setLoading(false)
      console.log('error...: ', err)
    }
  }

  const updatePassword = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const poolData = {
        UserPoolId: awsConfig.aws_user_pools_id,
        ClientId: awsConfig.aws_user_pools_web_client_id
      }
    
      const userPool = new CognitoUserPool(poolData)
      
      const authenticatedUser = await userPool.getCurrentUser()

      if (authenticatedUser != null) {
        authenticatedUser.getSession(function(err, session) {
          if (err) {
            setLoading(false)
            setError(err.message || JSON.stringify(err))
            return
          }
          // console.log('session validity: ' + session.isValid())

          if (oldPassword && newPassword)
      
          authenticatedUser.changePassword(oldPassword, newPassword, function(err, result) {
            if (err) {
              setLoading(false)
              setError(err.message)
              return
            }
            // console.log('call result: ' + result)
            setLoading(false)
            alert('Password update successful')
            navigate('/')
          })
        })
      }
    }
    catch (err) {
      setError(err.message || JSON.stringify(err))
      setLoading(false)
      console.log('error...: ', err)
    }
  }  

  return (
    <Paper className={classes.root} elevation={3}>
      <FormControl component="fieldset">
        <RadioGroup 
          row
          aria-label="login-details" 
          name="login-details" 
          value={updateItem} 
          onChange={(event) => setUpdateItem(event.target.value)}
        >
          <FormControlLabel value="email" control={<Radio />} label="Update other attributes" />
          <FormControlLabel value="password" control={<Radio />} label="Update password" />
        </RadioGroup>
      </FormControl>
      {updateItem === 'email' && stage === 0 &&
      <AuthForm instruction="Edit the attributes you'd like to change and click UPDATE" error={error}>
        <Email
          handleUpdate={(event) => setEmail(event.target.value)}
          email={email}
          autoComplete="new-password"
          placeholder="new email"
        />
        <NumberFormat
          placeholder="+614xxxxxxxx*"
          onChange={(event) => setPhoneNumber(event.target.value.trim())}
          name="phone_number"
          value={phoneNumber}
          type="tel"
          className="form-control"
          format="+614########"
          mask="_"
        />        
        <TextInput
          handleUpdate={(event) => setFirstname(event.target.value)}
          textInput={firstname}
          autoComplete="firstname"
          placeholder="firstname"
        />
        <TextInput
          handleUpdate={(event) => setSurname(event.target.value)}
          textInput={surname}
          autoComplete="surname"
          placeholder="surname"
        />
        <Button
          onClick={updateAttributes}
          type="submit"
          color="primary"
          fullWidth
          variant="contained"
          disabled={loading}
        >
          {loading ? null : 'Update'}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </AuthForm>}
      {updateItem === 'email' && stage === 1 &&
      <AuthForm 
        instruction= "Enter the verification code sent to your new email and click CONFIRM. Check your spam folder, if you don't receive the code in a few seconds."
        error={error}
      >
        <Email
          handleUpdate={null}
          disabled
          email={email}
          autoComplete="new-password"
          placeholder="new email"
        />
        <ConfirmationCode
          handleUpdate={(event) => setAuthCode(event.target.value.trim())}
          authCode={authCode}
          autoComplete="off"
        />
        <Button
          onClick={confirmEmail}
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading}
        >        
          {loading ? null : 'Confirm'}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </AuthForm>}
      {updateItem === 'password' && 
      <AuthForm instruction="Password requires at least 8 characters with letters and numbers" error={error}>
        <Password
          required  
          handleUpdate={(event) => setOldPassword(event.target.value)}
          password={oldPassword}
          autoComplete="new-password"
          name="oldPassword"
          placeholder="old password"
          id="old_password"
        />
        <Password
          required
          handleUpdate={(event) => setNewPassword(event.target.value)}
          password={newPassword}
          autoComplete="new-password"
          name="newPassword"
          placeholder="new password"
          id="new_password"
        />
        <Button
          onClick={updatePassword}
          type="submit"
          color="primary"
          fullWidth
          variant="contained"
          disabled={loading}
        >
          {loading ? null : 'Update'}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>          
      </AuthForm>}
    </Paper>
  )
}

export default UpdateUser
