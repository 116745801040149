import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { deletePOItem } from '../helpers/po-graphql'
import PurchaseOrderFilter from './inventory-po-filter'

const InventoryPurchaseOrder = ({suppliers}) => {
  const [purchaseOrder, setPurchaseOrder] = useState(null)
  const [items, setItems] = useState([])
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    let amount = 0
    items.forEach((item) => {amount = amount + item.qty * item.price})
    setAmount(amount)
  }, [items])

  const deleteItem = async(e) => {
    const index = e.currentTarget.value
    await deletePOItem(items[index].id)
    let existingItems = [...items]
    existingItems.splice(index, 1)
    setItems(existingItems)
  }

  return (
    <>
      <PurchaseOrderFilter 
        suppliers={suppliers} 
        amount={amount} 
        setItems={setItems} 
        items={items}
        purchaseOrder={purchaseOrder}
        setPurchaseOrder={setPurchaseOrder}
      />
      <Grid container justify="space-between" style={{marginTop: 40}} spacing={2}>
        <Grid item align="left" md={2}>
          Product code
        </Grid>
        <Grid item align="left" md={5}>
          Product name
        </Grid>
        <Grid item align="left" md={2}>
          Package
        </Grid>
        <Grid item align="left" md={1}>
          Qty
        </Grid>
        <Grid item align="left" md={1}>
          Price
        </Grid>
        <Grid item align="left" md={1}>
          Delete
        </Grid>
      </Grid>
      <hr style={{marginTop: 0}}/>
      {items.map((item, index) => (
        <Grid container justify="space-between" key={item.id} alignItems="center" style={{marginBottom: 4}} spacing={2}>
          <Grid item align="left" md={2}>
            {item.product.productCode}
          </Grid>
          <Grid item align="left" md={5}>
            {item.product.description}
          </Grid>
          <Grid item align="left" md={2}>
            {item.product.package}
          </Grid>
          <Grid item align="left" md={1}>
            <TextField
              margin="dense"
              type="number"
              fullWidth
              value={items[index].qty}
              onChange={(event) => {
                let updatedItems = [...items]
                updatedItems[index].qty = event.target.value
                setItems(updatedItems)
              }}
            />          
          </Grid>
          <Grid item align="left" md={1}>
            <TextField
              margin="dense"
              type="number"
              fullWidth
              value={items[index].price}
              onChange={(event) => {
                let updatedItems = [...items]
                updatedItems[index].price = event.target.value
                setItems(updatedItems)
              }}
            />          
          </Grid>
          <Grid item align="left" md={1}>
            <IconButton 
              aria-label="delete" 
              color='primary'
              value={index}
              onClick={deleteItem} 
              disabled={purchaseOrder.orderStatus !== "draft"}
            >
              <DeleteIcon/>
            </IconButton>          
            </Grid>
        </Grid>
      ))}
    </>
  )
}

export default InventoryPurchaseOrder