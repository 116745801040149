import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const DropdownSelection = ({value, handleChange, items}) => {
  return (
    <FormControl fullWidth>
      <Select
        native
        value={value}
        onChange={event => handleChange(event.target.value)}
      >
        {items.map((item, index) => <option key={index} value={item}>{item}</option>)}              
      </Select>
    </FormControl>                    
  )
}

export default DropdownSelection