import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { setStaff, logout, getStaff as getAppStaff } from './app-staff'
import { AuthForm, Email, Password, CustomAction, ConfirmationCode } from './auth-forms'
import NumberFormat from 'react-number-format'
import Button from '@material-ui/core/Button'
import { API, graphqlOperation } from 'aws-amplify'
import { getStaff }  from '../../graphql/queries'
import { createStaff }  from '../../graphql/mutations'
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { LoggedInContext } from '../context'
import awsConfig from "../../aws-exports"

const SignIn = () => {
  const {setLoggedIn} = useContext(LoggedInContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [authCode, setAuthCode] = useState('')
  const [stage, setStage] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  let cognitoUser = null

  useEffect(() => {
    setError('')
  }, [email, password, phoneNumber])  

  const addStaff = async (username, name) => {
    let existingStaff

    try {
      existingStaff = await API.graphql(graphqlOperation(getStaff, {id: username}))
    } catch (err) {
      console.log('Amplify getUser error...: ', err)
      return
    }
    
    if (!existingStaff.data.getStaff) {
      try {
        await API.graphql(graphqlOperation(createStaff, {
          input: {
            id: username,
            name: name,
            active: true
          }
        }))

      } catch (err) {
        setError(err.message || JSON.stringify(err))
        setLoading(false)
        console.log('Amplify createUser error...: ', err)
        return        
      }
    }
  }  

  const login = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)
      await Auth.signIn(email, password)
      cognitoUser = await Auth.currentAuthenticatedUser()
      //Check if the user is in Admin group
      const groups = cognitoUser.signInUserSession.accessToken.payload["cognito:groups"]

      const staffInfo = {
        ...cognitoUser.attributes,
        username: cognitoUser.username,
        groups: groups
      } 

      setStaff(staffInfo)
      setLoggedIn(true)
      addStaff(cognitoUser.username, `${staffInfo.given_name} ${staffInfo.family_name}`)
      setLoading(false)  

      if (!staffInfo.phone_number_verified) {
        // Verify phone number flow
        setPhoneNumber(staffInfo.phone_number)
        setStage(1)
      } else {
        navigate('/')
      }
    } catch (err) {
      setError(err.message || JSON.stringify(err))
      setLoading(false)
      console.log('error...: ', err)
    }
  }

  const updatePhoneNumber = async () => {
    let attributeList = []
    const phone = phoneNumber.replace(/ /g, "")
    const attribute = new CognitoUserAttribute({
      Name: 'phone_number',
      Value: phone,
    })
    attributeList.push(attribute)

    const poolData = {
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id
    }
  
    const userPool = new CognitoUserPool(poolData)
    const authenticatedUser = await userPool.getCurrentUser()
    if (authenticatedUser != null) {
      authenticatedUser.getSession(function(err, session) {
        if (err) {
          setLoading(false)
          setError(err.message || JSON.stringify(err))
          return
        }             

        authenticatedUser.updateAttributes(attributeList, function(err, result) {
          if (err) {
            setLoading(false)
            setError(err.message || JSON.stringify(err))
            return
          }
        })
      })

      // sign out and sign back in so that the updated phone number will be reflected in the authenticatedUser info
      logout()
      const staff = await Auth.signIn(email, password)
      const staffInfo = {
        ...staff.attributes,
        username: staff.username
      } 

      setStaff(staffInfo)
      addStaff(staff.username, `${staffInfo.given_name} ${staffInfo.family_name}`) 
    }
  }

  const confirmPhoneNumber = async (event) => {
    event.preventDefault()
    try {
      setLoading(true)

      if (stage === 1) {
        // User updated phone number on UI
        if (phoneNumber !== getAppStaff().phone_number) {
          await updatePhoneNumber()
        }

        cognitoUser = await Auth.currentAuthenticatedUser()

        cognitoUser.getAttributeVerificationCode('phone_number', {
          onSuccess: () => {console.log('Code sent')},
          onFailure: function(err) {
            setError(err.message || JSON.stringify(err))
          },
          inputVerificationCode: null
        })   
        setStage(2)
        setLoading(false)
      } else {
        cognitoUser = await Auth.currentAuthenticatedUser()
        cognitoUser.verifyAttribute('phone_number', authCode, {
          onSuccess: () => {console.log('Code verified successfully')},
          onFailure: function(err) {
            setError(err.message || JSON.stringify(err))
          }}
        )
        alert('Phone number was verified successfully')
        navigate('/')
      }
    } catch (err) {
      setError(err.message || JSON.stringify(err))
      setLoading(false)
      console.log('error confirming phone number...', err)
    }
  }

  return (
    <>
      {stage === 0 && 
      <AuthForm title="Sign in to your account" error={error}>
        <Email
          handleUpdate={(event) => setEmail(event.target.value.trim())}
          email={email}
          autoComplete="on"
        />
        <Password
          handleUpdate={(event) => setPassword(event.target.value.trim())}
          password={password}
          autoComplete="on"
        />
        <CustomAction
          padding={10}
          question="Forgot your password?"
          action="Reset password"
          cb={() => navigate("/reset-password")}
        />
        <Button
          onClick={login}
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading}
        >
          {loading ? null : 'Sign In'}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
        <CustomAction
          padding={10}
          question="No account?"
          action="Create account"
          cb={() => navigate("/signup")}
        />        
      </AuthForm>}
      {stage !== 0 && 
      <AuthForm 
        title="Verify your phone number" 
        instruction= {stage === 1 ? 
          "Check your phone number and click SEND CODE to receive a verification code on your phone"
          :
          "Enter the verification code and click CONFIRM"}
        error={error}
      >
        <NumberFormat
          disabled={stage === 2}
          placeholder="+614xxxxxxxx*"
          onChange={(event) => setPhoneNumber(event.target.value.trim())}
          name="phone_number"
          value={phoneNumber}
          type="tel"
          className="form-control"
          format="+614########"
          mask="_"
        />        
        <ConfirmationCode
          handleUpdate={(event) => setAuthCode(event.target.value.trim())}
          authCode={authCode}
          autoComplete="off"
        />
        <Button
          onClick={confirmPhoneNumber}
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disabled={loading}
          style={{
            marginBottom: 30
          }}
        >        
          {loading ? null : (stage === 1 ? 'Send Code' : 'Confirm')}
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </AuthForm>}
    </>
  )
}

export default SignIn
