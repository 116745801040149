import { API, graphqlOperation } from 'aws-amplify'
import { listInvoices, getInvoice } from '../graphql/queries'
import { createInvoice, updateInvoice } from '../graphql/mutations'

export const getInvoiceByNumber = async(invoiceNo) => {
  try {
    const result = await API.graphql(graphqlOperation(getInvoice, {id: invoiceNo}))
    return result.data.getInvoice
  } catch (err) {
    console.log('Amplify getInvoice error...: ', err)
  }
}

export const filterInvoices = async(filter) => {
  try {
    const result = await API.graphql({
      query: listInvoices,
      variables: {
        filter: filter
      }        
    })
    return result.data.listInvoices.items
  } catch (err) {
    console.log('Amplify listInvoices error...: ', err)
  }
}

export const modifyInvoice = async(invoiceData) => {
  try {
    const result = await API.graphql(graphqlOperation(updateInvoice, {
      input: invoiceData
    }))
    return result.data.updateInvoice
  } catch (err) {
    console.log('Amplify updateInvoice error...: ', err)
  }
}

export const newInvoice = async(invoiceData) => {
  try {
    await API.graphql(graphqlOperation(createInvoice, {
      input: invoiceData
    }))
  } catch (err) {
    console.log('Amplify createInvoice error...: ', err)
  }
}
