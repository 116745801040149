import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import Content from '../components/content'
import titleImg from '../images/bg8.jpg'
import { isLoggedIn } from '../components/auth/app-staff'
import { LoggedInContext } from '../components/context'
import Amplify from 'aws-amplify'
import config from '../aws-exports'
Amplify.configure(config)

const App = () => {
  const {loggedIn, setLoggedIn} = useContext(LoggedInContext)
  const tagline = loggedIn ? '' : 'Login to start your admin tasks'

  useEffect(() => {
    if (isLoggedIn())
      setLoggedIn(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [])

  return (
    <Content title='Aspire Medical Centre Eastwood Digital Workflow Portal'  titleImg={titleImg} tagline={tagline}>
      {loggedIn ? 
        null
        :
        <Link to='/signin'>Sign in</Link>
      }
    </Content>
  )
}

export default App