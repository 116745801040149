import React, { useState, useEffect, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

export default function PopupAttachment({triggerOpen, docType, handleAttachment}) {
  const [open, setOpen] = useState(false)
  const didMountRef = useRef(false)
  const [filePath, setFilePath] = useState('')
  
  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true)
      setFilePath('')
    }
    else {
      didMountRef.current = true
    }
  }, [triggerOpen])

  const closeDialog = () => {
    setOpen(false)      
  }

  return (
    <>
      <Dialog open={open} onBackdropClick={closeDialog}>
        <DialogTitle>{`Attach a ${docType} PDF to this purchase order`}</DialogTitle>        
        <DialogContent>
          <TextField
            id='doc-attachment'
            required
            margin="dense"
            type="file"
            fullWidth
            value={filePath}
            onChange={(event) => setFilePath(event.target.value)}
          />          
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpen(false)}
            color="primary" 
            fullWidth
          >
            Cancel
          </Button>
          <Button 
            onClick={() => {
              handleAttachment()
              setOpen(false)
            }
            } 
            color="primary" 
            fullWidth
            disabled={filePath.length === 0}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}