import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CentreAlignedButton from './centered-button'
import { getStaff } from './auth//app-staff'
import insurers from '../constants/insurers.json'
import paymentTypes from '../constants/payment-types.json'
import paymentStatuses from '../constants/payment-statuses.json'
import providers from '../constants/providers.json'
import DropdownInput from './dropdown-input'
import useDebounce from '../helpers/debounce'
import uploadDoc from '../helpers/uploadS3'
import { addReport } from '../helpers/report-graphql'
import { getInvoiceByNumber, modifyInvoice, newInvoice } from '../helpers/invoice-graphql'

const PaymentInput = ({invoice, updateCallback}) => {
  const staffInfo = getStaff()
  const staffName = `${staffInfo.given_name} ${staffInfo.family_name}`  
  const [invoiceNo, setInvoiceNo] = useState("")
  const [insurer, setInsurer] = useState(null)
  const [claimNo, setClaimNo] = useState("")
  const [patient, setPatient] = useState("")
  const [provider, setProvider] = useState(null)
  const [amount, setAmount] = useState(0)
  const [paymentType, setPaymentType] = useState({name: "HICAPS"})
  const [paymentStatus, setPaymentStatus] = useState({name: "Invoice created"})
  const [invoicePath, setInvoicePath] = useState("")
  const [chequePath, setChequePath] = useState("")
  const [createdBy, setCreatedBy] = useState(staffName)
  const [updatedBy, setUpdatedBy] = useState("")
  const [invoiceDate, setInvoiceDate] = useState(null)
  const [paymentDate, setPaymentDate] = useState(null)
  const [disbursed, setDisbursed] = useState(false)
  const [note, setNote] = useState("")
  const [existingInvoice, setExistingInvoice] = useState(false)
  const [updateRequestFromBrowser, setUpdateRequestFromBrowser] = useState(false)
  const debouncedInvoiceId = useDebounce(invoiceNo, 1000)

  const reset = () => {
    setInsurer(null)
    setClaimNo("")
    setPatient("")
    setAmount("")
    setPaymentType({name: "HICAPS"})
    setPaymentStatus({name: "Invoice created"})
    setProvider(null)
    setInvoiceDate(null) 
    setPaymentDate(null) 
    setCreatedBy(staffName)
    setUpdatedBy("")
    setInvoicePath("")
    setChequePath("")
    setDisbursed(false)
    setNote("")
    setExistingInvoice(false)
  }

  const initInvoice = (invoice) => {
    setExistingInvoice(true)
    if (invoice.insurer)
      setInsurer(invoice.insurer)
    
    if (invoice.claimNo)          
      setClaimNo(invoice.claimNo)
    
    setPatient(invoice.patient)
    setAmount(invoice.amount)
    setPaymentType({name: invoice.paymentType})
    setPaymentStatus({name: invoice.status})
    setProvider({name: invoice.provider})
    setDisbursed(invoice.claimDisbursedToProvider)
    setInvoiceDate(new Date(invoice.createdAt))
    if (invoice.paymentDate)
      setPaymentDate(new Date(invoice.paymentDate))
    setCreatedBy(invoice.createdBy)
    if (invoice.updatedBy)
      setUpdatedBy(invoice.updatedBy)
    if (invoice.note)
      setNote(invoice.note)
  }

  useEffect(() => {
    if (invoice) {
      setInvoiceNo(invoice.id)
      initInvoice(invoice)
      setUpdateRequestFromBrowser(true) 
    } 
  }, [invoice])
  
  useEffect(() => {
    const fetchInvoice = async() => {
      if (!existingInvoice && debouncedInvoiceId.length > 3) {
        const invoice = await getInvoiceByNumber(debouncedInvoiceId)
        if (invoice) 
          initInvoice(invoice)
        else 
          reset()
      }
    }
    fetchInvoice()
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [debouncedInvoiceId])

  useEffect(() => {
    if (existingInvoice)
      return
      
    if (paymentType.name === 'HICAPS' || paymentType.name === 'EFTPOS' || paymentType.name === 'Cash')
      setPaymentStatus({name: "Paid"})
  }, [paymentType, existingInvoice])

  const invoiceIncomplete = 
    invoiceNo?.length === 0 || 
    amount === 0 || 
    provider === null || 
    patient?.length === 0 || 
    (paymentType.name === "Insurance claim" && (claimNo === null || insurer === null)) || 
    (paymentType.name === "HICAPS" && insurer === null) ||
    (!existingInvoice &&  invoicePath.length === 0)

  const handleSubmit = async () => {
    let invoiceFileName = null
    let chequeFileName = null
    if (invoicePath) {
      invoiceFileName = `${invoiceNo}-invoice.pdf`
      uploadDoc('invoice', invoiceFileName)
      addReport(patient, amount, staffName, invoiceFileName)
    }

    if (chequePath) {
      chequeFileName = `${invoiceNo}-cheque.pdf`
      uploadDoc('cheque', chequeFileName)
      addReport(patient, amount, staffName, chequeFileName)
    }

    let invoiceData = {
      id: invoiceNo,
      patient: patient,
      provider: provider.name,
      amount: amount,
      paymentType: paymentType.name,
      status: paymentStatus.name,
      claimDisbursedToProvider: disbursed,
      createdAt: invoiceDate
    }

    if (invoiceFileName)
      invoiceData.invoiceDoc = invoiceFileName

    if (chequeFileName)
      invoiceData.chequeDoc = chequeFileName

    if (existingInvoice)
      invoiceData.updatedBy = staffName
    else
      invoiceData.createdBy = staffName
    
    if (paymentDate)
      invoiceData.paymentDate = paymentDate

    if (paymentType.name === "Insurance claim") {
      invoiceData.claimNo = claimNo
      invoiceData.insurer = insurer
    }

    if (paymentType.name === "HICAPS" || paymentType.name === "Insurance claim")
      invoiceData.insurer = insurer.name

    if (note.length > 0)
      invoiceData.note = note

    try {
      if (existingInvoice) {
        const updatedInvoice = await modifyInvoice(invoiceData)
        if (updateRequestFromBrowser) {
          updateCallback(updatedInvoice)
          setUpdateRequestFromBrowser(false)
        }
      } else {
        await newInvoice(invoiceData)
      }

      alert('Invoice saved successfully')
    } catch (err) {
      console.log('Amplify createInvoice/updateInvoice error...: ', err)
      alert('Invoice failed to save')
    }
    
    setInvoiceNo("")
    reset()
  }  

  return (
    <>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Invoice No."
            type="text"
            fullWidth
            value={invoiceNo}
            onChange={(event) => setInvoiceNo(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={insurer}
            setOption={setInsurer}
            options={insurers}
            label="Insurer"
            placeholder=""
          />          
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            margin="dense"
            label="Claim No."
            type="text"
            fullWidth
            value={claimNo}
            onChange={(event) => setClaimNo(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Patient's name"
            type="text"
            fullWidth
            value={patient}
            onChange={(event) => setPatient(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <Grid item align="left" md={3}>
          <TextField
            required
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(event) => setAmount(event.target.value.trim())}
          />
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={paymentType}
            setOption={setPaymentType}
            options={paymentTypes}
            label="Payment type"
            placeholder=""
          />          
        </Grid>
        <Grid item align="left" md={3}>
          <DropdownInput
            required
            disableClearable={true}
            option={provider}
            setOption={setProvider}
            options={providers}
            label="Provider"
            placeholder=""
          />          
        </Grid>
        <Grid item align="right" md={3}>
          <FormGroup>
            <FormControlLabel 
              control={<Switch checked={disbursed} onChange={() => setDisbursed(!disbursed)} value='disbursed'/>} 
              label="Claim disbursed to provider" 
            />
          </FormGroup>             
        </Grid>            
      </Grid>
      <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item align="left" md={3}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="invoice-date-picker"
              label="Select invoice date"
              value={invoiceDate}
              onChange={(date) => setInvoiceDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item align="left" md={3}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="payment-date-picker"
              label="Select payment date"
              value={paymentDate}
              onChange={(date) => setPaymentDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item align="left" md={3}>
          <TextField
            id='created_by'
            margin="dense"
            label="Created by"
            type="text"
            fullWidth
            value={createdBy}
            disabled
          />
        </Grid>
        <Grid item align="left" md={3}>
          <TextField
            id='updated_by'
            margin="dense"
            label="Updated by"
            type="text"
            fullWidth
            value={updatedBy}
            disabled
          />
        </Grid>
       </Grid> 
       <Grid container justify="space-around" alignItems='center' style={{marginTop: 30}} spacing={2} >
          <Grid item align="left" md={3}>
            <TextField
              id='invoice'
              required
              margin="dense"
              label="Invoice PDF"
              type="file"
              fullWidth
              value={invoicePath}
              onChange={(event) => setInvoicePath(event.target.value)}
            />
          </Grid>
          <Grid item align="left" md={3}>
            <TextField
              id='cheque'
              margin="dense"
              label="Cheque PDF"
              type="file"
              fullWidth
              value={chequePath}
              onChange={(event) => setChequePath(event.target.value)}
            />
          </Grid>
          <Grid item align="left" md={3}>
            <DropdownInput
              required
              disableClearable={true}
              option={paymentStatus}
              setOption={setPaymentStatus}
              options={paymentStatuses}
              label="Payment status"
              placeholder=""
            />                      
          </Grid>
          <Grid item align="left" md={3}>
            <TextField
              margin="dense"
              label="note"
              type="text"
              multiline
              rows="5"
              fullWidth
              variant="outlined"
              value={note}
              onChange={(event) => setNote(event.target.value)}
            />                   
          </Grid>
        </Grid>
      <CentreAlignedButton buttonText='Submit' onClick={handleSubmit} disabled={invoiceIncomplete}/>
    </>
  )
}

export default PaymentInput